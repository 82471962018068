import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  get today() {
    return environment.today || new Date();
  }
}
