import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../auth/auth.service';
import {Observable} from 'rxjs';
import {AlertService} from '../../core/alert/alert.service';
import {UserTransformerService} from './user-transformer.service';

@Injectable({
  providedIn: 'root'
})
export class CreatePilotRegistrationService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private alertService: AlertService,
    private transformer: UserTransformerService,
  ) {
  }

  checkCredentials(formValues): Observable<any> {
    const user = this.transformer.transformForm(formValues);

    return this.http.post<any>(
      environment.apiUrl + '/pilots/check-credentials',
      user
    );
  }


}
