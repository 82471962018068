import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { DateService } from 'src/app/core/date.service';

@Injectable({
  providedIn: 'root'
})
export class EndRegistrationGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private dateService: DateService,
    private configurationService: ConfigurationService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this.authService.isAdmin()) {
      return true;
    }

    const edition = this.configurationService.getEdition();
    if (this.dateService.today <= edition.end_of_registrations) {
      return true;
    }

    this.router.navigate(['/']);
  }
}
