import { FormControl } from '@angular/forms';

export function dateInterval(input: FormControl) {

  const begin = new Date('2023-07-19');
  const end = new Date('2023-07-31');
  const verifiedValue = new Date(input.value);

  const inDateInterval = (begin <= verifiedValue && verifiedValue <= end);

  return inDateInterval ? null : { dateInterval: true };
}
