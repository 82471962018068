import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormControlDisplayHelperService} from '../../../shared/form-control-display-helper.service';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../../core/alert/alert.service';
import {CreatePilotRegistrationService} from '../create-pilot-registration.service';

@Component({
  selector: 'app-create-pilot-credentials',
  templateUrl: './create-pilot-credentials.component.html',
  styleUrls: []
})
export class CreatePilotCredentialsComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() nextStep: EventEmitter<any> = new EventEmitter<any>();

  isFormSubmitted = false;

  constructor(
    public alertService: AlertService,
    public displayHelper: FormControlDisplayHelperService,
    public pilotRegistrationService: CreatePilotRegistrationService,
    public translate: TranslateService,
  ) {
  }

  ngOnInit() {
  }

  onSubmit() {
    this.isFormSubmitted = true;

    if (this.form.valid) {
      this.pilotRegistrationService.checkCredentials(this.form).subscribe(
        () => {
          this.nextStep.emit();
        },
        (errorData) => {
          const message = errorData.error.hasOwnProperty('message') ?
            errorData.error.message :
            this.translate.instant('pilot_account.verifErrorOccured')
          ;
          this.alertService.addAlert('danger', message);
        });
    }
  }
}
