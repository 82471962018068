import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Lang } from './lang.type';
import { Dictionary } from '../../shared/types';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale, frLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  static readonly defaultLang: Lang = 'en';

  private static readonly storageItemName = 'language';
  private lang: Lang = LangService.defaultLang;

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private bslocaleService: BsLocaleService
  ) {
    this.translate.use(LangService.defaultLang);
    const browserlang = navigator.language;
    const storageLang = localStorage.getItem(LangService.storageItemName);
    if (storageLang) {
      this.lang = <Lang>storageLang;
    } else if (browserlang === 'fr') {
      this.lang = 'fr';
    }

    this.translate.use(this.lang);
    this.setDatepickerLocale();
  }

  changeLang(newLang: Lang) {
    if (newLang !== this.lang
      && (newLang === 'en' || newLang === 'fr')) {
      this.translate.use(newLang);
      this.lang = newLang;
      localStorage.setItem(LangService.storageItemName, this.lang);
      this.setDatepickerLocale();
    }
  }

  getCurrentLang(): Lang {
    return this.lang;
  }

  getCountriesTranslation(lang: Lang) {
    let url = '/assets/locale/country-list/en-GB.json';
    if (lang === 'fr') {
      url = '/assets/locale/country-list/fr-FR.json';
    }
    return this.http.get<Dictionary>(url);
  }

  getRequestLocale() {
    return this.getCurrentLang() === 'fr' ? 'fr_FR' : 'en_US';
  }

  setDatepickerLocale() {
    if (this.lang === 'fr') {
      frLocale.invalidDate = 'Date non valide';
      defineLocale('fr', frLocale);
      this.bslocaleService.use('fr');
    } else {
      defineLocale('engb', enGbLocale);
      this.bslocaleService.use('engb');
    }
  }
}
