import { Component, OnInit, TemplateRef, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IsoDatePipe } from 'src/app/shared/pipes/iso-date.pipe';
import { IsoTimePipe } from 'src/app/shared/pipes/iso-time.pipe';
import { EventsService } from '../events.service';
import { EventFileType } from '../event-file-type.enum';
import { Event } from 'src/app/core/models/event';
import { AuthService } from 'src/app/auth/auth.service';
import { LangService } from 'src/app/core/lang/lang.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, AfterViewInit {

  static readonly MODAL_COOKIE_NAME = 'event_message_displayed';

  FileType = EventFileType;

  edition = this.configurationService.getEdition();

  get events() {
    return this.edition.events;
  }

  get displayedEvents() {
    return this
      .events
      .filter(e => e.published && (e.event_sheet_file_name || e.weather_forecast_file_name))
      .sort((e1, e2) => e1.order < e2.order ? 1 : -1);
  }

  shouldDisplayModal =
    this.cookieService.get(EventsComponent.MODAL_COOKIE_NAME) !== 'Y' &&
    !this.authService.isAdmin();

  modalRef: BsModalRef;

  @ViewChild('modal') templateRef: TemplateRef<any>;

  constructor(
    private configurationService: ConfigurationService,
    private authService: AuthService,
    private eventService: EventsService,
    private modalService: BsModalService,
    private langService: LangService,
    private cookieService: CookieService,
    private isoDatePipe: IsoDatePipe,
    private isoTimePipe: IsoTimePipe
  ) { }

  ngOnInit() {
    this.configurationService
      .getEditionListener()
      .subscribe(edition => this.edition = edition);
  }

  ngAfterViewInit(): void {
    if (this.shouldDisplayModal) {
      setTimeout(() => this.displayModal());
    }
  }

  markModalAsDisplayed() {
    this.cookieService.set(EventsComponent.MODAL_COOKIE_NAME, 'Y');
  }

  displayModal() {
    this.modalRef = this.modalService.show(this.templateRef);
    this.markModalAsDisplayed();
  }

  getEventFileUrl(event: Event, fileType: EventFileType): string {
    return this.eventService.getEventFileUrl(event, fileType);
  }

  get aeronauticalMapUrl(): string {
    return this.eventService.getAeronauticalMapUrl();
  }

  getLabel(event: Event): string {
    return  this.langService.getCurrentLang() === 'fr'
      ? event.label_fr
      : event.label_en;
  }

}
