import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { Login } from './models/login';
import { environment } from '../../environments/environment';
import { ApiLogin } from './models/api-login';
import { Role } from './role.type';
import { AlertService } from '../core/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from '../core/date.service';
@Injectable({ providedIn: 'root' })
export class AuthService {
  static readonly userParam = 'user';
  static readonly tokenParam = 'token';
  static readonly expirationParam = 'expiration';

  private token: string;
  private tokenTimer: any;
  private user;
  private roleListener = new Subject<Role>();
  private signedInListener = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private alertService: AlertService,
    private dateService: DateService,
    private translate: TranslateService
  ) {}

  getToken() {
    return this.token;
  }

  getUser() {
    return this.user;
  }

  getRole(): Role {
    const user = this.getUser();
    if (!user) {
      return 'anonymous';
    }

    const apiRole = user.roles[0];
    switch (apiRole) {
      case 'ROLE_ADMIN': return 'admin';
      case 'ROLE_REDACTOR': return 'redactor';
      default: return 'pilot';
    }

  }

  getRoleListener() {
    return this.roleListener.asObservable();
  }

  getSignedInListener() {
    return this.signedInListener.asObservable();
  }

  login(loginData: Login, firstSignIn = false) {
    this.http
      .post<ApiLogin> (
        environment.apiUrl + '/login',
        loginData
      )
      .subscribe(
        response => {
          const token = response.access_token;
          this.token = token;
          if (token) {
            const expiresInDuration = response.expires_in;
            this.setAuthTimer(expiresInDuration);
            this.user = response.user;
            this.emitRole();
            const now = this.dateService.today;
            const expirationDate = new Date(
              now.getTime() + expiresInDuration * 1000
            );
            this.saveAuthData(token, expirationDate, this.user);

            if (firstSignIn) {
              this.signedInListener.next(true);
            }
          }
        },
        error => {
          this.alertService.addAlert('danger', this.translate.instant('connection.Login failed'), 5);
          this.logout();
        },
      );
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = this.dateService.today;
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.user = authInformation.user;
      this.setAuthTimer(expiresIn / 1000);
      this.emitRole();
    }
  }

  logout() {
    this.token = null;
    this.user = null;
    this.emitRole();
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(['/']);
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.alertService.addAlert('warning', this.translate.instant('connection.Your connection has expired. Please sign in again.'));
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(token: string, expirationDate: Date, user: any) {
    localStorage.setItem(AuthService.tokenParam, token);
    localStorage.setItem(AuthService.expirationParam, expirationDate.toISOString());
    localStorage.setItem(AuthService.userParam, JSON.stringify(user));
  }

  private clearAuthData() {
    localStorage.removeItem(AuthService.tokenParam);
    localStorage.removeItem(AuthService.expirationParam);
    localStorage.removeItem(AuthService.userParam);
  }

  private getAuthData() {
    const token = localStorage.getItem(AuthService.tokenParam);
    const expirationDate = localStorage.getItem(AuthService.expirationParam);
    const user = JSON.parse(localStorage.getItem(AuthService.userParam));
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      user: user
    };
  }

  emitRole() {
    this.roleListener.next(this.getRole());
  }

  forgottenPassword(email: string) {
    return this.http.post(
      environment.apiUrl + '/reset-password',
      {email: email}
    );
  }

  isAdmin() {
    return this.getRole() === 'admin';
  }

  isPilot() {
    return this.getRole() === 'pilot';
  }

  isRedactor() {
    return this.getRole() === 'redactor';
  }
}
