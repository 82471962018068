import { Injectable } from '@angular/core';
import {
  ApiBalloonRegistration,
  ApiPilotEntry,
  ApiTeamMember,
  ApiCompetition,
  ApiPilotsSquare,
} from './models/api-balloon.interface';
import { BalloonCalculationService } from './balloon-calculation.service';
import { BalloonInformation } from './models/balloon-information.interface';
import { BalloonFiles } from './models/balloonFiles.interface';
import { Copilot } from './models/copilot.interface';
import { TeamMember } from './models/team-member.interface';
import { PilotSquareRegistration } from './models/pilot-square-registration.interface';
import { FormGroup } from '@angular/forms';
import { IsoDatePipe } from 'src/app/shared/pipes/iso-date.pipe';
import { StrToDatePipe } from 'src/app/shared/pipes/str-to-date.pipe';
import { Formula } from 'src/app/core/models/formula.interface';

@Injectable({
  providedIn: 'root'
})
export class BalloonTransformerService {

  constructor(
    private calculation: BalloonCalculationService,
    private isoDatePipe: IsoDatePipe,
    private strToDatePipe: StrToDatePipe
  ) { }

  transformForm(formData: FormGroup) {
    const formValues = formData.value;

    if(formValues.informations.manufacturingDate !== null && formValues.informations.manufacturingDate !== undefined) {
      formValues.informations.manufacturingDate.setMonth(0, 1);
    }
    let activeFormula = null;
    for (const formulaId in formValues.formulas) {
      if (formValues.formulas.hasOwnProperty(formulaId)) {
        const isActive = formValues.formulas[formulaId];

        if (isActive) {
          activeFormula = formulaId;
          break;
        }
      }
    }

    const registration = <ApiBalloonRegistration>{
      registration_number: formValues.informations.registrationNumber,
      name: formValues.informations.name,
      manufacturer: formValues.informations.manufacturer,
      type: formValues.informations.type,
      volume: formValues.informations.volume,
      description: formValues.informations.description,
      manufacturing_date: this.isoDatePipe.transform(formValues.informations.manufacturingDate),
      cdn_validity: this.isoDatePipe.transform(formValues.informations.cdnValidity),
      fly_time: formValues.informations.flightHours,
      insurance_company: formValues.informations.insuranceCompany,
      insurance_contract_number: formValues.informations.insuranceNumber,
      insurance_contract_valid_until: this.isoDatePipe.transform(formValues.informations.insuranceValidity),
      pilot_entry: <ApiPilotEntry>{
        id: formValues.pilot
      },
      copilots_entries: [... this.transformFormCopilots(formValues.copilots)],
      jackets: [... this.transformFormJackets(formValues.jackets)],
      pilots_squares: [... this.transformFormPilotSquare(formData.get('pilotSquare'))],
      competitions: [... this.transformFormCompetitions(formValues.competitions)],
      team_members: [... this.transformFormTeamMembers(formValues.teamMembers)]
    };

    if (activeFormula) {
      registration.formula = <Formula>{ id: activeFormula };
    }

    return registration;
  }

  transformFormCopilots(formArrayData) {
    const apiArray = [];
    for (const copilot of formArrayData) {
      const apiCopilot = { id: copilot.id };
      apiArray.push(apiCopilot);
    }

    return apiArray;
  }

  transformFormJackets(formArrayData) {
    const apiArray = [];
    const apiSizesArray = [];

    const apiJacketsOrder = {};
    let jacketCount = 0;
    for (const jacket of formArrayData) {
      const size = jacket.size;

      if (!apiJacketsOrder.hasOwnProperty(size)) {
        apiJacketsOrder[size] = 0;
      }

      apiJacketsOrder[size]++;
      jacketCount++;
    }
    apiSizesArray.push(apiJacketsOrder);

    const apiJacketObject = {
      sizes: apiSizesArray,
      quantity: jacketCount,
      price: this.calculation.jacketCalculationFromQuantity(jacketCount)
    };

    apiArray.push(apiJacketObject);
    return apiArray;
  }

  transformFormPilotSquare(formGroup) {
    const apiArray = [];
    for (const i in formGroup.controls) {
      if (!formGroup.controls.hasOwnProperty(i)) {
        continue;
      }

      const registration = formGroup.controls[i].value;
      const campsiteNumber = Math.abs(+registration.campsite);

      // only that field is checked beforehand, because if it is set,
      // the others will be validated by the form before the submit triggers the transform
      if (campsiteNumber > 0) {
        const validRegistration = <ApiPilotsSquare>{
          nb_people: +registration.people,
          nb_campsites: campsiteNumber,
          price: this.calculation.pilotSquareCalculationFromValues(formGroup.controls[i]),
          dates : []
        };

        for (const dateChoice of registration.dates || []) {
          if (dateChoice.isChecked) {
            validRegistration.dates.push(dateChoice.date);
          }
        }

        apiArray.push(validRegistration);
      }

    }
    return apiArray;
  }

  transformFormCompetitions(formGroupData) {
    const apiArray = [];
    for (const index in formGroupData) {
      if (formGroupData.hasOwnProperty(index)) {
        const isChecked = formGroupData[index];

        if (isChecked) {
          const checkedEvent = <ApiCompetition>{
            id: +index
          };
          apiArray.push(checkedEvent);
        }
      }
    }

    return apiArray;
  }

  transformFormTeamMembers(formArrayData) {
    const apiArray = [];

    for (const i in formArrayData) {
      if (formArrayData.hasOwnProperty(i)) {
        const member = formArrayData[i];
        const teamMember = <ApiTeamMember>{
          first_name: member.forname,
          last_name: member.name,
          birth_date: this.isoDatePipe.transform(member.birthDate),
          birth_city: member.birthCity,
          birth_country: member.birthCountry,
          price: this.calculation.teamMemberCalculationFromIndex(+i)
        };
        apiArray.push(teamMember);
      }
    }

    return apiArray;
  }

  revert(apiData) {
    const formulasValues = {};
    let formulaDetails = null;

    if (apiData.hasOwnProperty('formula')) {
      formulasValues[apiData.formula.id] = true;
      formulaDetails = apiData.formula;
    } else {
      for ( const formulaId in apiData.formulas) {
        if (apiData.formulas.hasOwnProperty(formulaId) && apiData.formulas[formulaId] === true) {
          formulasValues[formulaId] = true;
          formulaDetails = apiData.formulas[formulaId];
        }
      }
    }

    const registration = {
      id: apiData.id,
      pilot: '',
      status: apiData.status,
      formulaDetails: formulaDetails, // useless for form, but used for other calculations
      informations: <BalloonInformation>{
        registrationNumber: apiData.registration_number,
        type: apiData.type,
        volume: apiData.volume,
        name: apiData.name,
        manufacturer: apiData.manufacturer,
        manufacturingDate: this.strToDatePipe.transform(apiData.manufacturing_date),
        description: apiData.description,
        cdnValidity: this.strToDatePipe.transform(apiData.cdn_validity),
        flightHours: apiData.fly_time,
        insuranceCompany: apiData.insurance_company,
        insuranceNumber: apiData.insurance_contract_number,
        insuranceValidity: this.strToDatePipe.transform(apiData.insurance_contract_valid_until)
      },
      balloonFiles: <BalloonFiles>{
        cen: apiData.cen_file_name,
        insuranceCertificate: apiData.insurance_contract_file_name,
      },
      formulas: formulasValues,
      copilots: [],
      teamMembers: [],
      pilotSquare: [],
      jackets: [],
      zone: apiData.zone,
      payments: apiData.payments,
      earlyBird: apiData.early_bird
    };

    if (apiData.pilot_entry !== undefined) {
      registration.pilot = apiData.pilot_entry.id;
    }

    const activeCompetitions = {};
    if (apiData.hasOwnProperty('competitions')) {
      for (const event of apiData.competitions) {
        activeCompetitions[event.id] = true;
      }
    }
    registration['competitions'] = activeCompetitions;

    if (apiData.hasOwnProperty('copilots_entries')) {
      for (const copilot of apiData.copilots_entries) {
        registration.copilots.push(<Copilot>{
          id: copilot.id,
          name: copilot.pilot.user.last_name,
          forname: copilot.pilot.user.first_name,
          licenceNumber: copilot.pilot.licence_number
        });
      }
    }

    if (apiData.hasOwnProperty('team_members')) {
      for (const member of apiData.team_members) {
        registration.teamMembers.push(<TeamMember>{
          name: member.last_name,
          forname: member.first_name,
          birthDate: this.strToDatePipe.transform(member.birth_date),
          birthCity: member.birth_city,
          birthCountry: member.birth_country
        });
      }
    }

    // depending on the API route which was used to get the ballon, jackets structure is really different ...
    if (apiData.hasOwnProperty('jackets')) {
      for (const i in apiData.jackets) {
        if (apiData.jackets.hasOwnProperty(i)) {
          const jacketOrder = apiData.jackets[i];


          if (jacketOrder.hasOwnProperty('id')) {
            // the whole order is received

            // for whatever reason, sizes are stored in an array of objects containing number of jackets by size (key)
            // only index 0 of the array should exist, hence why an array ?
            // anyway, it is crawled to prevent existence of more indexes ...
            for (const unknownLevel of jacketOrder.sizes) {
              for (const size in unknownLevel) {
                if (unknownLevel.hasOwnProperty(size)) {
                  const jacketsOfSizeNumber = +unknownLevel[size];
                  for (let j = 0; j < jacketsOfSizeNumber; j++) {
                    registration.jackets.push({ size: size });
                  }
                }
              }
            }
          } else {
            // only a list by size
            registration.jackets.push(jacketOrder);
          }

        }
      }
    }

    if (apiData.hasOwnProperty('pilots_squares')) {
      for (const pilotSquare of apiData.pilots_squares) {
        registration.pilotSquare.push(<PilotSquareRegistration>{
          campsite: pilotSquare.nb_campsites,
          people: pilotSquare.nb_people,
          dates: pilotSquare.dates
        });
      }
    }
    return registration;
  }
}
