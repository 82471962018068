import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { Subject, Subscription, of, forkJoin } from 'rxjs';
import { AlertService } from '../../core/alert/alert.service';
import { map, catchError } from 'rxjs/operators';
import { BalloonTransformerService } from './balloon-transformer.service';
import { ApiBalloonRegistration, ApiCopilot } from './models/api-balloon.interface';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class BalloonRegistrationService {

  private currentBallonSub = new Subject<ApiBalloonRegistration[]>();
  private currentBalloons: ApiBalloonRegistration[] = [];
  private roleSub: Subscription;
  fileTypeConversion = {
    cen: 'cen',
    insuranceCertificate: 'insurance-contract'
  };

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private alertService: AlertService,
    private transformer: BalloonTransformerService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.init();
  }

  init() {
    if (this.authService.getRole() === 'pilot') {
      this.updateCurrentBalloons();
    }
    this.roleSub = this.authService.getRoleListener().subscribe(
      role => {
        if (role === 'pilot') {
          this.updateCurrentBalloons();
        }
      }
    );
  }

  getCurrentBalloonsListener() {
    return this.currentBallonSub.asObservable();
  }

  updateCurrentBalloons() {
    if (this.authService.getRole() !== 'pilot') {
      return;
    }
    this.http.get<ApiBalloonRegistration[]>(
      environment.apiUrl + '/balloons/pilot/' + this.authService.getUser().pilot_id,
    ).subscribe(
      data => {
        this.currentBalloons = data;
      },
      error => {
        this.currentBalloons = [];
      },
      () => {
        this.currentBallonSub.next(this.currentBalloons);
      }
    );
  }

  updateRegistrationAdminPart(balloon: number, formValues) {
    return this.http.post(
      environment.apiUrl + '/admin/balloon/' + balloon,
      {
        payment_status: formValues.paymentStatus,
        entry_status: formValues.registrationStatus,
        zone: formValues.registrationArea,
        payment_amount: formValues.newPaymentAmount,
        payment_method: formValues.newPaymentMethod
      }
    );
  }

  copilotSearch(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http
      .get<ApiCopilot[]>(environment.apiUrl + '/pilots/licence-number/' + encodeURIComponent(term)).pipe(
        map(pilotList => {
          for (const pilot of pilotList) {
            pilot['name'] = pilot.lastName;
            pilot['forname'] = pilot.firstName;
          }

          return pilotList;
        }),
        catchError(err => [])
      );
  }

  addRegistration(formValues: FormGroup, action: string) {
    const registration = this.transformer.transformForm(formValues);
    this.http.post<ApiBalloonRegistration>(
      environment.apiUrl + '/balloon',
      registration
    ).subscribe(
      balloon => {
        this.filesUpload(balloon, formValues).subscribe(
          uploadsReturn => {},
          errorReturn => { this.addManageAlert(balloon, balloon.id, action, true); },
          () => { this.addManageAlert(balloon, balloon.id, action); }
        );
      },
      errorData => {
        this.updateCurrentBalloons();
        const message = errorData.error.hasOwnProperty('message') ?
          errorData.error.message :
          this.translate.instant('balloon.An error occurred while registering your balloon')
        ;
        this.alertService.addAlert('danger', message, 0);
      }
    );
  }

  addManageAlert(balloonData, registrationId: number, action: string, documentErrors = false) {
    let type = 'success',
    message = '',
    documentMessage = '',
    duration = this.alertService.defaultDuration;

    if (documentErrors) {
      type = 'warning';
      documentMessage = ', but an error occurred while uploading documents';
      duration = 0;
    }
    this.updateCurrentBalloons();

    if (action === 'finalize') {
      this.finalize(balloonData.id).subscribe(
        updatedBallon => {
          this.updateCurrentBalloons();
          message = 'balloon.Your information have been saved' + documentMessage + '.';
          this.alertService.addAlert(type, this.translate.instant(message), duration);
          this.router.navigate(['/balloon/' + balloonData.id]);
        },
      );
    } else {
      // tslint:disable-next-line:max-line-length
      message = 'balloon.Your information have been saved' + documentMessage + ', but your registration isn\'t considered finalized';
      this.updateCurrentBalloons();
      this.alertService.addAlert(type, this.translate.instant(message), duration);
      this.router.navigate(['/balloon/' + balloonData.id]);
    }
  }

  finalize(balloon: number) {
    return this.http.post(
      environment.apiUrl + '/balloon/' + balloon + '/finalize',
      {}
    );
  }

  filesUpload(balloon, formValues) {
    const files = formValues.value.balloonFiles;
    const fileUploads = [];
    if (files.cen instanceof File) {
      fileUploads.push(this.addFile(files.cen, balloon.id, this.fileTypeConversion.cen));
    }
    if (files.insuranceCertificate instanceof File) {
      fileUploads.push(this.addFile(files.insuranceCertificate, balloon.id, this.fileTypeConversion.insuranceCertificate));
    }

    return forkJoin(fileUploads);
  }

  updateRegistration(registrationId: number, formValues, action: string) {
    const registration = this.transformer.transformForm(formValues);
    this.http.put<ApiBalloonRegistration>(
      environment.apiUrl + '/balloon/' + registrationId,
      registration
    ).subscribe(
      balloonData => {
        this.filesUpload(balloonData, formValues).subscribe(
          uploadsReturn => {},
          errorReturn => { this.updateManageAlert(balloonData, registrationId, action, true); },
          () => {
            this.updateManageAlert(balloonData, registrationId, action);
          }
        );
      },
      error => {
        const message = error.error.hasOwnProperty('message') ?
          error.error.message :
          this.translate.instant('balloon.An error occurred while updating your balloon')
        ;
        this.alertService.addAlert('danger', message, 0);
      }
    );
  }

  updateManageAlert(balloonData, registrationId: number, action: string, documentErrors = false) {
    let type = 'success',
    message = '',
    redirect = null,
    documentMessage = '',
    duration = this.alertService.defaultDuration;

    if (documentErrors) {
      type = 'warning';
      documentMessage = ', but an error occurred while uploading documents';
      duration = 0;
    }

    this.updateCurrentBalloons();
      if (action === 'finalize') {
        this.finalizeRegistration(registrationId).subscribe(
          data => {
            this.updateCurrentBalloons();
          }
        );
        message = 'balloon.Your information has been sent to the organization you can proceed to the payment.';
      } else if (action === 'pay') {
        if (balloonData.amount_paid) {
          message = 'balloon.Your information have been updated' + documentMessage + ', please pay the additionals by Credit Card.';
          redirect = '/pay/' + balloonData.id + '/cb';
        } else {
          message = 'balloon.Your information have been updated' + documentMessage + ', please choose your payment mean.';
          redirect = '/pay/' + balloonData.id;
        }
      } else {
        if (balloonData.amount_paid) {
          message = 'balloon.Your information have been updated, no additional fees' + documentMessage + '.';
        } else {
          message = 'balloon.Your information have been updated' + documentMessage + '.';
        }
      }

      if (redirect !== null) {
        this.router.navigate([redirect]);
      }
      this.alertService.addAlert(type, this.translate.instant(message), duration);
  }

  finalizeRegistration(balloon: number) {
    return this.http.post(
      environment.apiUrl + '/balloon/' + balloon + '/finalize',
      {}
    );
  }

  getRegistration(id: number) {
    return this.http.get<ApiBalloonRegistration>(
      environment.apiUrl + '/balloon/' + id
    );
  }

  fileDownload(balloon: number, fileType: string) {
    return this.http.get(
      environment.apiUrl + '/balloon/' + balloon + '/download/' + this.fileTypeConversion[fileType],
      { observe: 'response', responseType: 'blob' }
    );
  }

  changeFileStatus(balloon: number, fileType: string, action: string) {
    return this.http.post<ApiBalloonRegistration>(
      environment.apiUrl + '/admin/balloon/' + balloon + '/' + this.fileTypeConversion[fileType] + '/' + action,
      {}
    );
  }

  addFile(file: File, balloon: number, type: string) {
    const postData = new FormData();
    postData.append('file', file);
    return this.http.post(
      environment.apiUrl + '/balloon/' + balloon + '/upload/' + type,
      postData
    );
  }
}
