import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import { LangService } from '../lang/lang.service';
import { PilotRegistrationService } from '../../pilot/pilot-registration/pilot-registration.service';
import { Role } from '../../auth/role.type';
import { Lang } from '../lang/lang.type';
import { BalloonRegistrationService } from '../../balloon/balloon-registration/balloon-registration.service';
import { ApiBalloonRegistration } from '../../balloon/balloon-registration/models/api-balloon.interface';
import { ConfigurationService } from '../configuration.service';
import { DateService } from '../date.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {

  role: Role = 'anonymous';
  roleSub: Subscription;
  currentPilot;
  currentPilotSub: Subscription;
  currentBalloons: ApiBalloonRegistration[] = [];
  currentBalloonsSub: Subscription;
  isCollapsed = true;
  route = this.activatedRoute.url;


  constructor(
    private langService: LangService,
    private authService: AuthService,
    private pilotRegistrationService: PilotRegistrationService,
    private balloonRegistrationService: BalloonRegistrationService,
    private dateService: DateService,
    private configuration: ConfigurationService,
    private router: Router,
    private activatedRoute: ActivatedRoute
    ) { }

  ngOnInit() {
    this.role = this.authService.getRole();
    this.getPilotReg();
    this.roleSub = this.authService.getRoleListener().subscribe(role => {
      this.role = role;
      this.getPilotReg();
    });
    this.currentPilotSub = this.pilotRegistrationService.getCurrentPilotListener().subscribe(currentPilot => {
      this.currentPilot = currentPilot;
    });

    this.currentBalloonsSub = this.balloonRegistrationService.getCurrentBalloonsListener().subscribe(currentBalloons => {
      this.currentBalloons = currentBalloons;
    });
  }

  getPilotReg(): void {
    if (this.authService.getUser() !== undefined && this.authService.getRole() === 'pilot') {
      this.pilotRegistrationService.getRegistration(this.authService.getUser().pilot_id).subscribe(
        pilot => this.currentPilot = pilot
      );
    }
  }

  getPilotSmiley(): string {
    if (this.currentPilot === undefined) {
      return '';
    }

    const filesStatuses = [
      this.currentPilot.medical_certificate_status,
      this.currentPilot.logbook_status,
      this.currentPilot.licence_status
    ];

    return filesStatuses.some(status => status !== 'valid')
      ? 'meh'
      : 'smile';
  }

  getBalloonSmiley(balloon): string {
    if (balloon.status !== 'valid') {
      return '';
    }

    let smiley = 'smile';
    for (const fileStatus of [balloon.cen_status, balloon.insurance_contract_status]) {
      if (fileStatus !== 'valid') {
        smiley = 'meh';
      }
    }

    return smiley;
  }

  onChangeLang(lang: Lang): void {
    this.langService.changeLang(lang);
  }

  onLogout(): void {
    this.isCollapsed = true;
    this.authService.logout();
  }

  ngOnDestroy() {
    this.roleSub.unsubscribe();
    this.currentPilotSub.unsubscribe();
    this.currentBalloonsSub.unsubscribe();
  }

  getLangLinksClasses(lang: Lang) {
    return {
      'btn': true,
      'btn-sm': true,
      'btn-primary': true,
      'active': lang === this.langService.getCurrentLang()
    };
  }

  toggleNavBar(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  displayNewBalloonLink() {
    return this.dateService.today < this.configuration.getEdition().additional_balloon_until;
  }

  onPrint() {
    (window as any).print();
  }

}
