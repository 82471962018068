import {Injectable} from '@angular/core';
import {ApiUser} from 'src/app/pilot/create-pilot-registration/models/apiPilotCredentials';

@Injectable({
  providedIn: 'root'
})
export class UserTransformerService {
  transformForm(formGroups) {
    return <ApiUser>{
      email: formGroups.value.email,
      password: formGroups.value.password,
      old_password: formGroups.value.oldPassword
    };
  }
}
