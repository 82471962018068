if (String.prototype.padStart === undefined ) {
  String.prototype.padStart = function(this: string, maxLength: number, fillString?: string): string {
    let result = this;
    while (this.length < maxLength) {
      result = fillString + result;
    }
    return result;
  };
}

if (String.prototype.padEnd === undefined ) {
  String.prototype.padEnd = function(this: string, maxLength: number, fillString?: string): string {
    let result = this;
    while (this.length < maxLength) {
      result += fillString;
    }
    return result;
  };
}
