import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { EventsService } from '../events.service';
import { Event } from 'src/app/core/models/event';
import { EventFileType } from '../event-file-type.enum';
import { IsoDatePipe } from 'src/app/shared/pipes/iso-date.pipe';
import { IsoTimePipe } from 'src/app/shared/pipes/iso-time.pipe';
import { AlertService } from 'src/app/core/alert/alert.service';
import { LangService } from 'src/app/core/lang/lang.service';

@Component({
  selector: 'app-admin-events',
  templateUrl: './admin-events.component.html',
  styleUrls: ['./admin-events.component.scss']
})
export class AdminEventsComponent implements OnInit {

  edition = this.configurationService.getEdition();
  FileType = EventFileType;

  get mapUpdatedAtDate() {
    return this.isoDatePipe.transform(this.edition.aeronautical_map_updated_at);
  }

  get mapUpdatedAtTime() {
    return this.isoTimePipe.transform(this.edition.aeronautical_map_updated_at);
  }

  get events() {
    return this.edition.events;
  }

  constructor(
    private configurationService: ConfigurationService,
    private eventService: EventsService,
    private alertService: AlertService,
    private langService: LangService,
    private isoDatePipe: IsoDatePipe,
    private isoTimePipe: IsoTimePipe
  ) { }

  ngOnInit() {
  }

  async togglePublished(event: Event) {
    event.published = !event.published;
    try {
      const apiEvent = await this.eventService.publish(event);
      event.published = apiEvent.published;
    } catch (e) {
      event.published = !event.published;
      this.alertService.addAlert('danger', e.error.message, 10);
    }

  }

  async uploadAeronauticalMap(files: FileList) {
    const file = files[0];
    try {
      this.edition = await this.eventService.uploadAeronauticalMap(file);
    } catch (e) {
      this.alertService.addAlert('danger', e.error.message, 10);
    }
  }


  async uploadEventFile(event: Event, fileType: EventFileType, files: FileList) {
    const file = files[0];
    try {
      const apiEvent = await this.eventService.uploadEventFile(event, fileType, file);
      event.event_sheet_file_name = apiEvent.event_sheet_file_name;
      event.weather_forecast_file_name = apiEvent.weather_forecast_file_name;
    } catch (e) {
      this.alertService.addAlert('danger', e.error.message, 10);
    }
  }

  async removeEventFile(event: Event, fileType: EventFileType) {
    try {
      const apiEvent = await this.eventService.removeEventFile(event, fileType);
      event.event_sheet_file_name = apiEvent.event_sheet_file_name;
      event.weather_forecast_file_name = apiEvent.weather_forecast_file_name;
    } catch (e) {
      this.alertService.addAlert('danger', e.error.message, 10);
    }
  }

  getLabel(event: Event): string {
    return  this.langService.getCurrentLang() === 'fr'
      ? event.label_fr
      : event.label_en;
  }

}
