import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { Period } from 'src/app/core/period.enum';

@Component({
  selector: 'app-waiting-component',
  templateUrl: './waiting.component.html',
  styleUrls: ['./waiting.component.scss']
})
export class WaitingComponent implements OnInit {
  titleKey: string;
  messageKey: string;
  centered = false;

  constructor(
    private configurationService: ConfigurationService
  ) {
    if (this.configurationService.getPeriod() === Period.beforeEdition) {
      this.titleKey = 'message.end_of_registration_title';
      this.messageKey = 'message.end_of_registrations';
    } else {
      this.titleKey = 'message.after_edition';
      this.messageKey = null;
      this.centered = true;
    }

  }

  ngOnInit() {
  }

}
