import { version } from './version';

export const environment = {
  env: 'integration',
  production: true,
  apiUrl: 'https://integration.api.pilote.pilatre-de-rozier.com',
  version: version,
  // change to simulate date
  today: null,
  editionRefreshRate: 60000
};
