import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { PaymentService } from '../payment.service';
import { BalloonRegistrationService } from '../../balloon/balloon-registration/balloon-registration.service';

@Component({
  selector: 'app-payment-verification',
  templateUrl: './payment-verification.component.html',
  styleUrls: ['./payment-verification.component.scss']
})
export class PaymentVerificationComponent implements OnInit {

  payment = null;
  balloon = null;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private balloonService: BalloonRegistrationService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (!paramMap.has('paymentId') || !paramMap.has('status')) {
        this.router.navigate(['/']);
      } else {
        const paymentId = +paramMap.get('paymentId');
        this.paymentService.getPaymentInfos(paymentId).subscribe(
          paymentData => {
            this.payment = paymentData;
          }
        );
      }
    });
  }

}
