import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BalloonRegistrationService } from '../../balloon/balloon-registration/balloon-registration.service';
import { ActivatedRoute } from '@angular/router';
import { ApiBalloonRegistration } from '../../balloon/balloon-registration/models/api-balloon.interface';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { PaymentService } from '../payment.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  @ViewChild('cbPayButton') private cbPayButton: ElementRef;
  id: number;
  apiBalloon: ApiBalloonRegistration = <ApiBalloonRegistration>{};
  paymentForm = new FormGroup({
    method: new FormControl('', Validators.required)
  });
  paymentMethod = null;
  paymentAmount = 0;
  cbFormInputs = [];
  isLoaded = false;

  constructor(
    private paymentService: PaymentService,
    private balloonRegistrationService: BalloonRegistrationService,
    private route: ActivatedRoute,
  ) {
    this.id = +route.snapshot.params.balloonId;
  }

  ngOnInit() {
    this.balloonRegistrationService.getRegistration(this.id).subscribe(
      balloonData =>  {
        this.apiBalloon = balloonData;
        this.paymentAmount = this.apiBalloon.amount_due - this.apiBalloon.amount_paid;
        if (this.route.snapshot.params.paymentMean && this.route.snapshot.params.paymentMean === 'cb') {
          const lastPayment = this.apiBalloon.payments[this.apiBalloon.payments.length - 1];
          this.paymentAmount = lastPayment.amount;
          this.paymentMethod = 'CB';
          this.redirectToBank();
        }

        this.isLoaded = true;
      }
    );
  }

  onSubmitForm(event) {
    this.paymentService.registerPayment(
      this.apiBalloon.id,
      this.paymentForm.value.method,
      this.apiBalloon.amount_due - this.apiBalloon.amount_paid,
    ).subscribe(
      payment => {
        this.balloonRegistrationService.updateCurrentBalloons();
        this.paymentMethod = payment.method;

        // credit card
        if (this.paymentMethod === 'CB') {
          this.redirectToBank();
        }
      }
    );
  }

  redirectToBank() {
    this.cbFormInputs = [];
    this.paymentService.getPaymentCb(this.apiBalloon.id).subscribe(
      transaction => {
        for (const field in transaction) {
          if (transaction.hasOwnProperty(field)) {
            const value = transaction[field];
            this.cbFormInputs.push({field: field, value: value});
          }
        }
        setTimeout(
          () => { this.cbPayButton.nativeElement.click(); },
          500
        );
      }
    );
  }

  resetPaymentMethod() {
    this.paymentMethod = null;
    this.paymentForm.get('method').patchValue('');
  }

}
