import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { ConfigurationService } from '../core/configuration.service';
import { Period } from '../core/period.enum';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(
    private authService:  AuthService,
    private configurationService:  ConfigurationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.redirectIfNeeded();

    this.authService.getRoleListener().subscribe(role => {
      this.redirectIfNeeded();
    });
  }

  redirectIfNeeded() {
    const isInWaitingPeriod = this.configurationService.isInWaitingPeriod();
    const isDuringEdition = this.configurationService.getPeriod() === Period.duringEdition;
    const isAdminLoginRoute = this.router.url === '/admin/connection';
    let route = null;

    if (this.authService.isAdmin()) {
      if (isDuringEdition || isInWaitingPeriod) {
        route = '/admin/flights';
      } else {
        route = '/admin/dashboard';
      }
    } else if (this.authService.isRedactor()) {
      route = '/admin/flights';
    } else if (isInWaitingPeriod && !isAdminLoginRoute) {
      route = '/message';
    } else if (isDuringEdition && !isAdminLoginRoute) {
      route = '/flights';
    } else if (this.authService.isPilot()) {
      route = '/pilot';
    }

    if (route !== null) {
      this.router.navigate([route]);
    }

  }



}
