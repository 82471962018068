import { Injectable } from '@angular/core';
import { Formula } from '../../core/models/formula.interface';
import { FormArray, FormGroup } from '@angular/forms';
import { ConfigurationService } from '../../core/configuration.service';
import { DateService } from 'src/app/core/date.service';

@Injectable({
  providedIn: 'root'
})
export class BalloonCalculationService {

    formulas = this.configuration.getFormulas();
    competitionsList = this.configuration.getCompetitions();
    edition = this.configuration.getEdition();
    now = this.dateService.today;

    constructor(
      private configuration: ConfigurationService,
      private dateService: DateService
  ) { }

  getDatesControls(formGroup: FormGroup) {
    const datesFormArray = formGroup.get('dates') as FormArray;
    return datesFormArray.controls || [];
  }

  // @TODO change algo to get price by registration, then sum it to factorize with below method
  pilotSquareCalculationFromForm(formArray: FormArray) {
    const campsiteUnitPrice = this.edition.pilots_square_price;
    let campsiteNightNumber = 0;
    for (const pilotSquare of formArray.controls) {
      const campsiteNumber = Math.abs(+pilotSquare.value.campsite);
      const datesFormArray = pilotSquare.get('dates') as FormArray;
      for (const pilotSquareDate of datesFormArray.controls) {
        if (pilotSquareDate.value.isChecked === true) {
          campsiteNightNumber += campsiteNumber;
        }
      }
    }
    return campsiteNightNumber * campsiteUnitPrice;
  }

  pilotSquareCalculationFromValues(formGroup: FormGroup) {
    const campsiteNumber = +formGroup.value.campsite;
    const dates = formGroup.get('dates') as FormArray;

    let nightNumber = 0;
    for (const date of dates.controls) {
      if (date.value.isChecked) {
        nightNumber++;
      }
    }
    return this.pilotSquareCalculation(campsiteNumber, nightNumber);
  }

  pilotSquareCalculation(campsites: number, dates: number) {
    return campsites * dates * this.configuration.getEdition().pilots_square_price;
  }

  jacketCalculationFormForm(formArray: FormArray) {
    const paidJacketNumber = formArray.controls.length - 1; // remove free jacket from amount
    return  paidJacketNumber * this.configuration.getEdition().jacket_price;
  }

  jacketCalculationFromQuantity(quantity: number) {
    return this.configuration.getEdition().jacket_price * (quantity - 1);
  }

  teamMemberCalculationFromForm(formArray: FormArray) {
    let paidTeamMemberNumber =
      formArray.controls.length - this.configuration.getEdition().nb_free_team_members; // remove free team members from amount
    if (paidTeamMemberNumber < 0) {
      paidTeamMemberNumber = 0;
    }
    return paidTeamMemberNumber * this.configuration.getEdition().team_member_price;
  }

  teamMemberCalculationFromIndex(index: number) {
    return index > this.configuration.getEdition().nb_free_team_members - 1
      ? this.configuration.getEdition().team_member_price : 0;
  }

  formulaCalculationFromForm(formGroup: FormGroup, balloonReg) {
    for (const formulaId in formGroup.value) {
      if (formGroup.value.hasOwnProperty(formulaId) && formGroup.value[formulaId]) {
        const currentFormula = this.formulas.find(formula => formula.id === +formulaId);
        if (typeof currentFormula !== 'undefined') {
          return this.getFormulaPrice(currentFormula, balloonReg);
        }
      }
    }
    return 0;
  }

  getFormulaPrice(formula: Formula, balloonReg) {
    if (balloonReg.id !== undefined) {
      if (balloonReg.earlyBird) {
        return formula.early_bird_price;
      }
      return formula.price;
    }
    if (this.now < new Date(formula.early_bird_until)) {
      return formula.early_bird_price;
    }
    return formula.price;
  }
}
