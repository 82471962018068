import {DatePipe} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {TranslateLoader, TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {Ng2CsvModule} from 'ng2csv';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {NgxCaptchaModule} from 'ngx-captcha';
import {environment} from '../environments/environment';
import {AdminComponent} from './admin/admin.component';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app_routing';
import {AuthInterceptor} from './auth/auth.interceptor';
import {AuthService} from './auth/auth.service';
import {LoginComponent} from './auth/login/login.component';
import {SignUpComponent} from './auth/sign-up/sign-up.component';
import {BalloonRegistrationComponent} from './balloon/balloon-registration/balloon-registration.component';
import {AlertComponent} from './core/alert/alert.component';
import {ConfigurationService} from './core/configuration.service';
import {NavComponent} from './core/nav/nav.component';
import {HomeComponent} from './home/home.component';
import {PaymentVerificationComponent} from './payment/payment-verification/payment-verification.component';
import {PaymentComponent} from './payment/payment/payment.component';
import {PilotRegistrationComponent} from './pilot/pilot-registration/pilot-registration.component';
import {MapToIterable} from './shared/pipes/map-to-iterable.pipe';
import {BsDatepickerConfig, BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {IsoDatePipe} from './shared/pipes/iso-date.pipe';
import {LanguageInterceptor} from './shared/language.interceptor';
import {StrToDatePipe} from './shared/pipes/str-to-date.pipe';
import {EventsComponent} from './events/events/events.component';
import {WaitingComponent} from './waiting/waiting.component';
import {AdminEventsComponent} from './events/admin-events/admin-events.component';
import {CookieService} from 'ngx-cookie-service';
import {IsoTimePipe} from './shared/pipes/iso-time.pipe';
import './core/polyfills/string-polyfills';
import {CreatePilotRegistrationComponent} from './pilot/create-pilot-registration/create-pilot-registration.component';
import {
  CreatePilotCredentialsComponent
} from './pilot/create-pilot-registration/create-pilot-credentials/create-pilot-credentials.component';
import {
  CreatePilotInformationsComponent
} from './pilot/create-pilot-registration/create-pilot-informations/create-pilot-informations.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/locale/', '.json?v=' + environment.version);
}

@NgModule({
  declarations: [
    MapToIterable,
    IsoDatePipe,
    IsoTimePipe,
    StrToDatePipe,
    AppComponent,
    NavComponent,
    LoginComponent,
    CreatePilotRegistrationComponent,
    PilotRegistrationComponent,
    BalloonRegistrationComponent,
    SignUpComponent,
    HomeComponent,
    AlertComponent,
    AdminComponent,
    PaymentComponent,
    PaymentVerificationComponent,
    EventsComponent,
    WaitingComponent,
    AdminEventsComponent,
    CreatePilotCredentialsComponent,
    CreatePilotInformationsComponent,
    AdminEventsComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    NgxCaptchaModule,
    BsDatepickerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    Ng2CsvModule
  ],
  providers: [
    AuthService,
    ConfigurationService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: editionDataInit,
      deps: [ConfigurationService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: sizesDataInit,
      deps: [ConfigurationService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: formulasDataInit,
      deps: [ConfigurationService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: CompetitionsDataInit,
      deps: [ConfigurationService],
      multi: true,
    },
    DatePipe,
    TranslatePipe,
    IsoDatePipe,
    IsoTimePipe,
    StrToDatePipe,
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function editionDataInit(configuration: ConfigurationService) {
  return () => configuration.initEditionData();
}

export function sizesDataInit(configuration: ConfigurationService) {
  return () => configuration.initSizesData();
}

export function formulasDataInit(configuration: ConfigurationService) {
  return () => configuration.initFormulasData();
}

export function CompetitionsDataInit(configuration: ConfigurationService) {
  return () => configuration.initCompetitionsData();
}

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    containerClass: 'theme-dark-blue',
    dateInputFormat: 'DD/MM/YYYY',
  });
}
