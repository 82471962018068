import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiBalloonRegistration } from '../balloon/balloon-registration/models/api-balloon.interface';
import { Subject } from 'rxjs';
import { AlertService } from '../core/alert/alert.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private balloonList: ApiBalloonRegistration[] = [];
  private balloonListSub = new Subject<ApiBalloonRegistration[]>();

  constructor(
    private http: HttpClient,
    private alertService: AlertService
  ) {
  }

  getBalloonsRegistrations() {
    return this.balloonList;
  }

  getBalloonsRegistrationsListener() {
    return this.balloonListSub.asObservable();
  }

  askForRefresh() {
    this.http.get<ApiBalloonRegistration[]>(environment.apiUrl + '/balloon/export').subscribe(
      data => {
        this.balloonList = data;
        this.balloonListSub.next(this.getBalloonsRegistrations());
      },
      error => {
        this.alertService.addAlert('danger', 'An error occurred while loading balloon list.');
      }
    );
  }

  changePilotSquareStatus(status: boolean) {
    return this.http.post<any>(
      environment.apiUrl + '/admin/pilots-square',
      { open: status }
    );
  }
}
