import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'iso'
})
export class IsoDatePipe implements PipeTransform {
  transform(date: Date) {

    if (!date) {
      return null;
    }

    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }

    return `${year}-${month}-${day}`;
  }
}
