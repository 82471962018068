import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LangService } from '../core/lang/lang.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private langService: LangService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.langService !== undefined) {
      const request = req.clone({
        headers: req.headers.set('Accept-Language', this.langService.getRequestLocale())
      });
      return next.handle(request);
    }
    return next.handle(req);
  }
}
