import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Edition } from './models/edition.interface';
import { Formula } from './models/formula.interface';
import { ApiCompetition } from '../balloon/balloon-registration/models/api-balloon.interface';
import { Event } from './models/event';
import { Period } from './period.enum';
import { DateService } from './date.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class ConfigurationService {
  private edition: Edition;
  private sizes: Array<string>;
  private formulas: Array<Formula>;
  private competitions: Array<ApiCompetition>;
  private editionSubject: BehaviorSubject<Edition>;
  private editionListener: Observable<Edition>;

  constructor(
    private dateService: DateService,
    private http: HttpClient
  ) { }

  async initEditionData(): Promise<Edition> {
    await this.updateEditionData();

    this.editionSubject = new BehaviorSubject<Edition>(this.edition);
    this.editionListener = this.editionSubject.asObservable();

    setInterval(async () => {
        await this.updateEditionData();
        this.editionSubject.next(this.edition);
      },
      environment.editionRefreshRate
    );

    return this.edition;
  }

  private async updateEditionData() {
    const apiEdition = await this.http.get<Edition>(environment.apiUrl + '/edition').toPromise();

    // dates conversion
    const workEdition = apiEdition as any;
    apiEdition.additional_balloon_until = new Date(workEdition.additional_balloon_until);
    apiEdition.additional_pilots_square_until = new Date(workEdition.additional_pilots_square_until);
    apiEdition.additional_team_member_until = new Date(workEdition.additional_team_member_until);
    apiEdition.early_bird_until = new Date(workEdition.early_bird_until);
    apiEdition.end_of_registrations = new Date(workEdition.end_of_registrations);
    apiEdition.start = new Date(workEdition.start);
    apiEdition.end = new Date(workEdition.end);
    apiEdition.aeronautical_map_updated_at = new Date(workEdition.aeronautical_map_updated_at);

    this.edition = apiEdition;
  }

  async initSizesData(): Promise<Array<string>> {
    this.sizes = await this.http.get<Array<string>>(environment.apiUrl + '/jacket-sizes').toPromise();
    return this.sizes;
  }

  async initFormulasData(): Promise<Array<Formula>> {
    this.formulas = await this.http.get<Array<Formula>>(environment.apiUrl + '/formulas').toPromise();
    return this.formulas;
  }

  async initCompetitionsData(): Promise<Array<ApiCompetition>> {
    this.competitions = await this.http.get<Array<ApiCompetition>>(environment.apiUrl + '/competitions').toPromise();
    return this.competitions;
  }

  getEditionListener(): Observable<Edition> {
     return this.editionListener;
  }

  getEdition(): Edition {
    return this.edition;
  }

  getEvents(): Array<Event> {
    return this.edition.events;
  }

  getEditionData(key: string) {
    return this.edition[key];
  }

  getSizes() {
    return this.sizes;
  }

  getFormulas() {
    return this.formulas;
  }

  getCompetitions() {
    return this.competitions;
  }

  isInWaitingPeriod() {
    const period = this.getPeriod();
    return period === Period.beforeEdition || period === Period.afterEdition;
  }

  getPeriod(): Period {
    const now = this.dateService.today;
    if (now <= this.edition.end_of_registrations) {
      return Period.registrations;
    }

    if (now < this.edition.start) {
      return Period.beforeEdition;
    }

    if (now <= this.edition.end) {
      return Period.duringEdition;
    }

    return Period.afterEdition;
  }
}

