import {Injectable} from '@angular/core';
import {ApiEdition, ApiPilot, ApiPilotRegistration, ApiUser} from './models/apiPilot';
import {PilotRegistration} from './models/pilot-registration';
import {PersonalData} from './models/personal-data';
import {ArrivalDeparture} from './models/arrival-departure';
import {Commitment} from './models/commitment';
import {ConnectionData} from './models/connection-data';
import {PilotCoordinates} from './models/pilot-coordinates';
import {PilotLicence} from './models/pilot-licence';
import {Phones} from './models/phones';
import {PilotFiles} from './models/pilot-files';
import {ConfigurationService} from '../../core/configuration.service';
import {IsoDatePipe} from 'src/app/shared/pipes/iso-date.pipe';
import {StrToDatePipe} from 'src/app/shared/pipes/str-to-date.pipe';

@Injectable({
  providedIn: 'root'
})
export class PilotTransformerService {

  constructor(
    private configuration: ConfigurationService,
    private isoDatePipe: IsoDatePipe,
    private strToDatePipe: StrToDatePipe
  ) { }

  transformForm(formData) {
    const formGroups = formData.controls;
    return <ApiPilotRegistration>{
      accepted_terms_conditions: formGroups.commitment.value.isAccepted,
      receive_newsletter: formGroups.commitment.value.receiveNewsletter,
      arrival: this.isoDatePipe.transform(formGroups.arrivalDeparture.value.arrival),
      fly_time: formGroups.licence.value.flightHours,
      departure: this.isoDatePipe.transform(formGroups.arrivalDeparture.value.departure),
      first_participation: formGroups.personalData.value.firstParticipation === 'true',
      edition: <ApiEdition>{
        id: this.configuration.getEdition().id
      },
      pilot: <ApiPilot>{
        birth_date: this.isoDatePipe.transform(formGroups.personalData.value.birthDate),
        birth_city: formGroups.personalData.value.birthCity,
        birth_country: formGroups.personalData.value.birthCountry,
        nationality: formGroups.personalData.value.nationality,
        licence_number: formGroups.licence.value.number,
        licence_country: formGroups.licence.value.licenceCountry,
        licence_issued_at: this.isoDatePipe.transform(formGroups.licence.value.delivery),
        address: formGroups.coordinates.value.address,
        zip_code: formGroups.coordinates.value.zipCode,
        city: formGroups.coordinates.value.city,
        country: formGroups.coordinates.value.country,
        phone1: formGroups.phones.value.phone,
        phone2: formGroups.phones.value.cellphone,
        user: <ApiUser>{
          first_name: formGroups.personalData.value.forname,
          last_name: formGroups.personalData.value.name,
          gender: formGroups.personalData.value.sex,
          email: formGroups.connectionData.value.email,
          password: formGroups.connectionData.value.password,
          old_password: formGroups.connectionData.value.oldPassword
        }
      }
    };
  }

  revert(apiRegistration: ApiPilotRegistration) {
    const pilot = apiRegistration.pilot;
    return <PilotRegistration>{
      id: apiRegistration.id,
      status: apiRegistration.entry_status,
      personalData: <PersonalData>{
        name: pilot.user.last_name,
        forname: pilot.user.first_name,
        birthCity: pilot.birth_city,
        birthCountry: pilot.birth_country,
        birthDate: this.strToDatePipe.transform(pilot.birth_date),
        nationality: pilot.nationality,
        sex: pilot.user.gender,
        firstParticipation: apiRegistration.first_participation !== undefined ? apiRegistration.first_participation.toString() : null
      },
      arrivalDeparture: <ArrivalDeparture>{
        arrival: apiRegistration.arrival ? this.strToDatePipe.transform(apiRegistration.arrival) : null,
        departure: apiRegistration.departure ? this.strToDatePipe.transform(apiRegistration.departure) : null
      },
      commitment: <Commitment>{
        isAccepted: apiRegistration.accepted_terms_conditions,
        receiveNewsletter: apiRegistration.receive_newsletter
      },
      connectionData: <ConnectionData>{
        email: pilot.user.email
      },
      coordinates: <PilotCoordinates>{
        address: pilot.address,
        zipCode: pilot.zip_code,
        city: pilot.city,
        country: pilot.country
      },
      licence: <PilotLicence>{
        delivery: this.strToDatePipe.transform(pilot.licence_issued_at),
        number: pilot.licence_number,
        flightHours: apiRegistration.fly_time,
        licenceCountry: pilot.licence_country
      },
      phones: <Phones>{
        phone: pilot.phone1,
        cellphone: pilot.phone2
      },
      pilotFiles: <PilotFiles>{
        licence: apiRegistration.licence_file_name,
        medicalCertificate: apiRegistration.medical_certificate_file_name,
        flightLog: apiRegistration.logbook_file_name
      }
    };
  }
}
