import {
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export const atLeastOne = (
  validator: ValidatorFn,
  controls: string[] = null,
  errorKey: string = null
) => (group: FormGroup): ValidationErrors | null => {
  const key = errorKey || 'atLeastOne';
  const errorObject = {};
  errorObject[key] = true;

  if (!controls) {
    controls = Object.keys(group.controls);
  }

  const hasAtLeastOne = group && group.controls && controls
    .some(k => !validator(group.controls[k]));

  return hasAtLeastOne ? null : errorObject;
};
