import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiPilot, ApiPilotRegistration} from './models/apiPilot';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../auth/auth.service';
import {Login} from '../../auth/models/login';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {AlertService} from '../../core/alert/alert.service';
import {PilotTransformerService} from './pilot-transformer.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PilotRegistrationService {
  private currentPilotListener = new Subject<ApiPilotRegistration>();
  private currentPilot: ApiPilotRegistration = null;
  private roleSub: Subscription;
  fileTypeConversion = {
    licence: 'licence',
    medicalCertificate: 'medical-certificate',
    flightLog: 'logbook'
  };
  private token: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private alertService: AlertService,
    private transformer: PilotTransformerService,
    private translate: TranslateService
  ) {
    this.roleSub = this.authService.getRoleListener().subscribe(
      role => {
        if (role === 'pilot') {
          this.updateCurrentPilot();
        } else {
          this.setCurrentPilot(null);
        }
      }
    );
  }

  getPilotFromApi(apiPilot: ApiPilotRegistration) {
    return this.transformer.revert(apiPilot);
  }

  setCurrentPilot(pilot: ApiPilotRegistration) {
    this.currentPilot = pilot;
    this.currentPilotListener.next(this.currentPilot);
  }

  addRegistration(formValues) {
    const registration = this.transformer.transformForm(formValues);

    this.http.post<ApiPilot>(
      environment.apiUrl + '/pilots',
      registration
    ).subscribe(
      pilotData => {
        const loginData = <Login>{
          email: pilotData.email,
          password: registration.pilot.user.password
        };
        const subscription = this.currentPilotListener.subscribe(
          pilot => {
            this.filesUpload(pilot.id, formValues).subscribe(
              uploadsReturn => {
                this.alertService.addAlert(
                  'success',
                  this.translate.instant('pilot_account.Your information have been saved and your account created')
                );
                this.updateCurrentPilot();
              },
              errorReturn => {
                this.alertService.addAlert(
                  'warning',
                  // tslint:disable-next-line:max-line-length
                  this.translate.instant('pilot_account.Your information have been saved and your account created, but an error occurred while uploading documents'),
                  0
                );
              },
            );
            subscription.unsubscribe();
          }
        );

        this.authService.login(loginData, true);
      },
      errorData => {
        const message = errorData.error.hasOwnProperty('message') ?
          errorData.error.message :
          this.translate.instant('pilot_account.An error occurred while saving your information')
        ;
        this.alertService.addAlert('danger', message, 0);
      }
    );
  }

  filesUpload(pilotId, formValues) {
    const files = formValues.value.pilotFiles;
    const fileUploads = [];
    for (const type of ['licence', 'medicalCertificate', 'flightLog']) {
      if (files[type] instanceof File) {
        fileUploads.push(this.addFile(files[type], pilotId, this.fileTypeConversion[type]));
      }
    }

    return forkJoin(fileUploads);
  }

  fileDownload(pilot: number, fileType: string) {
    return this.http.get(
      environment.apiUrl + '/pilots/' + pilot + '/download/' + this.fileTypeConversion[fileType],
      { observe: 'response', responseType: 'blob' }
    );
  }

  changeFileStatus(pilot: number, fileType: string, action: string) {
    return this.http.post<ApiPilotRegistration>(
      environment.apiUrl + '/admin/pilot/' + pilot + '/' + this.fileTypeConversion[fileType] + '/' + action,
      {}
    );
  }

  addFile(file: File, pilot: number, type: string) {
    const postData = new FormData();
    postData.append('file', file);
    return this.http.post(
      environment.apiUrl + '/pilots/' + pilot + '/upload/' + type,
      postData
    );
  }

  updateRegistration(registrationId: number, formValues) {
    const registration = this.transformer.transformForm(formValues);
    this.http.put(
      environment.apiUrl + '/pilots/' + registrationId,
      registration,
      { observe: 'response' }
    ).subscribe(
      response => {
        const pilotData = <ApiPilot> response.body;
        if (!this.currentPilot || this.currentPilot.id === pilotData.id) {
          const pilotId = this.currentPilot === null ? registrationId : this.currentPilot.id;
          const token = response.headers.get('X-token');

          if (token !== null) {
            this.updateToken(token);
          }

          if (this.currentPilot !== null) {
            this.updateCurrentPilot();
          }

          let alertSent = false;
          this.filesUpload(pilotId, formValues).subscribe(
            uploadsReturn => {},
            errorReturn => {
              this.alertService.addAlert(
                'warning',
                this.translate.instant('pilot_account.Your information have been saved, but an error occurred while uploading documents.'),
                0
              );
              alertSent = true;
            },
            () => {
              if (!alertSent) {
                if (this.currentPilot == null) {
                  this.alertService.addAlert('success', this.translate.instant('pilot_account.Your information have been saved.'));
                } else {
                  this.alertService.addAlert('success', this.translate.instant('pilot_account.success_pilot_update'), 40);
                }
              }
            }
          );
        }
      },
      errorData => {
        const message = errorData.error.hasOwnProperty('message') ?
          errorData.error.message :
          this.translate.instant('pilot_account.An error occurred while saving your information')
        ;
        this.alertService.addAlert('danger', message, 0);
      }
    );
  }

  updateCurrentPilot() {
    this.getOwnRegistration()
    .subscribe(pilotData => this.setCurrentPilot(pilotData));
  }

  updateToken(token: string) {
    localStorage.removeItem('token');
    localStorage.setItem('token', token);
  }

  getRegistration(id: number) {
    return this.http.get<ApiPilotRegistration>(
      environment.apiUrl + '/pilots/' + id
    );
  }

  getOwnRegistration() {
    return this.http.get<ApiPilotRegistration>(
      environment.apiUrl + '/pilot'
    );
  }

  getCurrentPilotListener() {
    return this.currentPilotListener.asObservable();
  }
}
