import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {PilotRegistrationComponent} from './pilot/pilot-registration/pilot-registration.component';
import {BalloonRegistrationComponent} from './balloon/balloon-registration/balloon-registration.component';
import {AdminComponent} from './admin/admin.component';
import {PilotPageGuard} from './auth/guards/pilot-page.guard';
import {AdminGuard} from './auth/guards/admin.guard';
import {BalloonPageGuard} from './auth/guards/balloon-page.guard';
import {PaymentComponent} from './payment/payment/payment.component';
import {PaymentVerificationComponent} from './payment/payment-verification/payment-verification.component';
import {EndRegistrationGuard} from './auth/guards/end-registration.guard';
import {EventsComponent} from './events/events/events.component';
import {ManageEventsGuard} from './auth/guards/manage-events.guard';
import {EventsDisplayGuard} from './auth/guards/events-display.guard';
import {WaitingComponent} from './waiting/waiting.component';
import {AdminEventsComponent} from './events/admin-events/admin-events.component';
import {WaitingGuard} from './auth/guards/waiting.guard';
import {CreatePilotRegistrationComponent} from './pilot/create-pilot-registration/create-pilot-registration.component';
import {AnonymousGuard} from './auth/guards/anonymous.guard';

const routes: Routes = [

  // always
  {path: '', component: HomeComponent, canActivate: []}, // always allowed but will redirect

  // before end of registration
  {path: 'register/pilot', component: CreatePilotRegistrationComponent, canActivate: [AnonymousGuard, EndRegistrationGuard]},
  {path: 'pilot', component: PilotRegistrationComponent, canActivate: [EndRegistrationGuard]},
  {path: 'pilot/:pilotId', component: PilotRegistrationComponent, canActivate: [PilotPageGuard, EndRegistrationGuard]},
  {path: 'balloon', component: BalloonRegistrationComponent, canActivate: [BalloonPageGuard, EndRegistrationGuard]},
  {path: 'balloon/:balloonId', component: BalloonRegistrationComponent, canActivate: [BalloonPageGuard, EndRegistrationGuard]},
  {path: 'pay/:balloonId', component: PaymentComponent, canActivate: [BalloonPageGuard, EndRegistrationGuard]},
  {path: 'pay/:balloonId/:paymentMean', component: PaymentComponent, canActivate: [BalloonPageGuard, EndRegistrationGuard]},

  // during edition
  {path: 'flights', component: EventsComponent, canActivate: [EventsDisplayGuard]},
  {
    path: 'payment-verification/:paymentId/:status',
    component: PaymentVerificationComponent,
    canActivate: [BalloonPageGuard, EndRegistrationGuard]
  },

  // before edition and after edition
  { path: 'message', component: WaitingComponent, canActivate: [WaitingGuard] },

  // admin
  { path: 'admin/connection', component: HomeComponent, canActivate: [] },
  { path: 'admin/flights', component: AdminEventsComponent, canActivate: [ManageEventsGuard] },
  { path: 'admin/dashboard', component: AdminComponent, canActivate: [AdminGuard] },

  // 404
  { path: '**', component: HomeComponent, canActivate: [] }, // always allowed but will redirect

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [PilotPageGuard, AdminGuard, BalloonPageGuard]
})
export class AppRoutingModule {}
