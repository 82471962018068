import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { DateService } from 'src/app/core/date.service';
import { AuthService } from '../auth.service';
import { Period } from 'src/app/core/period.enum';

@Injectable({
  providedIn: 'root'
})
export class WaitingGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private dateService: DateService,
    private configurationService: ConfigurationService,
    private router: Router
    ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      if (this.authService.isAdmin()) {
        return true;
      }

      if (this.configurationService.isInWaitingPeriod()) {
        return true;
      }

      this.router.navigate(['/']);
  }
}
