import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from './admin.service';
import {Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {DisplayBsClassService} from '../shared/display-bs-class.service';
import {ApiBalloonRegistration} from '../balloon/balloon-registration/models/api-balloon.interface';
import {ApiPilotRegistration} from '../pilot/pilot-registration/models/apiPilot';
import {Ng2CsvService} from 'ng2csv';
import {ConfigurationService} from '../core/configuration.service';
import {AlertService} from '../core/alert/alert.service';
import {LangService} from '../core/lang/lang.service';
import {Dictionary} from '../shared/types';
import {Lang} from '../core/lang/lang.type';
import {PaymentService} from '../payment/payment.service';
import {DateService} from '../core/date.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  preserveWhitespaces: true
})
export class AdminComponent implements OnInit, OnDestroy {
  balloonList: ApiBalloonRegistration[];
  formattedBalloonList = [];
  balloonListSub: Subscription;
  today: string;
  countriesList: Dictionary = {};
  formulas = this.configurationService.getFormulas();
  competitionsList = this.configurationService.getCompetitions();
  jacketSizes = this.configurationService.getSizes();
  currentSort = { field: 'id', order: 'ASC' };
  filter = '';
  statusLabel = {
    saved: 'Enregistré',
    waiting: 'Finalisée',
    valid: 'Validée',
    invalid: 'Refusée'
  };
  paymentStatusLabel = {
    rejected: 'Rejeté',
    canceled: 'Annulé',
    gift: 'Offert',
    waiting: 'En attente',
    valid: 'Validé'
  };
  pilotSquareOpen = this.configurationService.getEdition().pilots_square_open;

  constructor(
    private adminService: AdminService,
    private datePipe: DatePipe,
    private ng2Csv: Ng2CsvService,
    private translate: TranslateService,
    public bsClass: DisplayBsClassService,
    private configurationService: ConfigurationService,
    private dateService: DateService,
    private alertService: AlertService,
    public langService: LangService,
    public paymentService: PaymentService
  ) { }

  ngOnInit() {

    this.adminService.askForRefresh();
    this.balloonList = this.adminService.getBalloonsRegistrations();
    this.formatBalloonList();

    this.subscribeCountries('fr');

    this.balloonListSub = this.adminService.getBalloonsRegistrationsListener()
      .subscribe(
        balloons => {
          this.balloonList = balloons;
          this.formatBalloonList();
        },
        error => {
          this.alertService.addAlert('danger', 'Une erreur s\'est produite lors du chargement.');
        }
      );

    this.today = this.datePipe.transform(this.dateService.today, 'yyyy-MM-dd');
  }

  ngOnDestroy() {
    this.balloonListSub.unsubscribe();
  }

  getDocumentTooltip(balloon: ApiBalloonRegistration) {
    const missingStatus = "missing";
    const waitingStatus = "waiting";
    const rejectStatus = "rejected";
    const pilotEntry = balloon.pilot_entry as ApiPilotRegistration;

    const statusToVerify = [
      balloon.insurance_contract_status,
      balloon.cen_status,
      pilotEntry.licence_status,
      pilotEntry.medical_certificate_status,
      pilotEntry.logbook_status
    ];

    if (statusToVerify.some((status) => status === rejectStatus)) {
      return this.translate.instant('balloon.rejected_documents');
    } else if (statusToVerify.some((status) => status === missingStatus)) {
      return this.translate.instant('balloon.missing_documents');
    } else if (statusToVerify.some((status) => status === waitingStatus)) {
      return this.translate.instant('balloon.waiting_documents');
    }

    for (const copilot of balloon.copilots_entries) {
      let statusCopilotToVerify = [
        copilot.licence_status,
        copilot.medical_certificate_status,
        copilot.logbook_status
      ];

      if (statusCopilotToVerify.some((status) => status === rejectStatus)) {
        return this.translate.instant('balloon.rejected_documents');
      } else if (statusCopilotToVerify.some((status) => status === missingStatus)) {
        return this.translate.instant('balloon.missing_documents');
      } else if (statusCopilotToVerify.some((status) => status === waitingStatus)) {
        return this.translate.instant('balloon.waiting_documents');
      }

    }

    return this.translate.instant('balloon.valid_documents');
  }

  getStatusLabel(status: string) {
    return this.statusLabel[status];
  }

  getPaymentStatusLabel(status: string) {
    return this.paymentStatusLabel[status];
  }

  translateRegistrationStatus(status: string) {
    return this.statusLabel[status];
  }

  exportBalloonsCsv() {
    // create an empty model to be sure to always have all columns
    const model = this.makeBalloonCsvModel();
    const exportData = [];
    for (const balloon of this.balloonList) {
      const pilotSquare = balloon.pilots_squares[0];
      let row = {
        'immatriculation ballon': balloon.registration_number,
        'marque ballon': balloon.manufacturer,
        'volume ballon': balloon.volume,
        'nombre équipiers': balloon.team_members.length,
        'formule choisie': balloon.formula.label_fr,
        'statut inscription': this.translateRegistrationStatus(balloon.status),
      };

      if (pilotSquare !== undefined) {
        row['nombre emplacements pilot\'s square'] = pilotSquare.nb_campsites,
          row['nombre personnes pilot\'s square'] = pilotSquare.nb_people;

        const crawlDate = new Date(this.configurationService.getEdition().start); // clone
        const end = this.configurationService.getEdition().end;
        while (crawlDate <= end) {
          const dateObject = crawlDate;
          const theDate = this.datePipe.transform(dateObject, 'dd/MM');
          const checkedDate = this.datePipe.transform(dateObject, 'yyyy-MM-dd');
          row[theDate] = pilotSquare.dates.some(x => x === checkedDate) ? '1' : '';
          crawlDate.setDate(crawlDate.getDate() + 1);
        }
      }

      for (const event of balloon.competitions) {
        row[event.label_fr] = '1';
      }

      let jacketTotalOrder = 0;
      const jacketTotalBySize = {};
      for (const jacketOrder of balloon.jackets) {
        jacketTotalOrder += jacketOrder.price;
        for (const jacket of jacketOrder.sizes) {
          for (const size in jacket) {
            if (jacket.hasOwnProperty(size)) {
              if (!jacketTotalBySize.hasOwnProperty(size)) {
                jacketTotalBySize[size] = 0;
              }
              jacketTotalBySize[size] += jacket[size];
            }
          }
        }
      }
      row['prix total vestes'] = jacketTotalOrder;


      row['prénom pilote'] = balloon.pilot_entry.pilot.user.first_name;
      row['nom pilote'] = balloon.pilot_entry.pilot.user.last_name;
      row['date naissance pilote'] = balloon.pilot_entry.pilot.birth_date;
      row['ville naissance pilote'] = balloon.pilot_entry.pilot.birth_city;
      row['pays naissance pilote'] = this.countriesList[balloon.pilot_entry.pilot.birth_country];

      row = { ...row, ...jacketTotalBySize };

      for (let i = 0; i < balloon.copilots_entries.length; i++) {
        const copilot = balloon.copilots_entries[i].pilot;
        row['prénom pilote additionnel ' + (i + 1)] = copilot.user.first_name;
        row['nom pilote additionnel ' + (i + 1)] = copilot.user.last_name;
        row['date naissance pilote additionnel ' + (i + 1)] = copilot.birth_date;
        row['ville naissance pilote additionnel ' + (i + 1)] = copilot.birth_city;
        row['pays naissance pilote additionnel ' + (i + 1)] = this.countriesList[copilot.birth_country];
      }

      for (let i = 0; i < balloon.team_members.length; i++) {
        const member = balloon.team_members[i];
        row['prénom équipier ' + (i + 1)] = member.first_name;
        row['nom équipier ' + (i + 1)] = member.last_name;
        row['date naissance équipier ' + (i + 1)] = member.birth_date;
        row['ville naissance équipier ' + (i + 1)] = member.birth_city;
        row['pays naissance équipier ' + (i + 1)] = this.countriesList[member.birth_country];
      }

      exportData.push(
        { ...model, ...row }
      );
    }
    this.ng2Csv.download(
      exportData,
      'balloons.csv'
    );
  }

  makeBalloonCsvModel() {
    let maxCopilot = 0;
    let maxTeamMembers = 0;
    for (const balloon of this.balloonList) {
      const copilots = balloon.copilots_entries.length;
      const teamMembers = balloon.team_members.length;

      maxCopilot = Math.max(maxCopilot, copilots);
      maxTeamMembers = Math.max(maxTeamMembers, teamMembers);
    }

    const model = {
      'immatriculation ballon': '',
      'marque ballon': '',
      'volume ballon': '',
      'nombre équipiers': '',
      'formule choisie': '',
      'statut inscription': '',
      'nombre emplacements pilot\'s square': '',
      'nombre personnes pilot\'s square': ''
    };

    const crawlDate = new Date(this.configurationService.getEdition().start) // clone;
    const end = this.configurationService.getEdition().end;
    while (crawlDate <= end) {
      const theDate = this.datePipe.transform(crawlDate, 'dd/MM');
      model[theDate] = '';
      crawlDate.setDate(crawlDate.getDate() + 1);
    }

    for (const event of this.competitionsList) {
      model[event.label_fr] = '';
    }

    model['prix total vestes'] = '';

    for (const size of this.jacketSizes) {
      model[size] = '';
    }

    model['prénom pilote'] = '';
    model['nom pilote'] = '';
    model['date naissance pilote'] = '';
    model['ville naissance pilote'] = '';
    model['pays naissance pilote'] = '';

    for (let i = 0; i < maxCopilot; i++) {
      model['prénom pilote additionnel ' + (i + 1)] = '';
      model['nom pilote additionnel ' + (i + 1)] = '';
      model['date naissance pilote additionnel ' + (i + 1)] = '';
      model['ville naissance pilote additionnel ' + (i + 1)] = '';
      model['pays naissance pilote additionnel ' + (i + 1)] = '';
    }

    for (let i = 0; i < maxTeamMembers; i++) {
      model['prénom équipier ' + (i + 1)] = '';
      model['nom équipier ' + (i + 1)] = '';
      model['date naissance équipier ' + (i + 1)] = '';
      model['ville naissance équipier ' + (i + 1)] = '';
      model['pays naissance équipier ' + (i + 1)] = '';
    }

    return model;
  }

  exportPilotsCsv() {
    const exportData = [];
    const model = this.makePilotCsvModel();

    for (const balloon of this.balloonList) {
      const pilotSquare = balloon.pilots_squares[0];
      const otherBalloons = this
        .balloonList
        .filter(b =>
          b.id !== balloon.id // not the current balloon itself
          &&
          b.pilot_entry.id === balloon.pilot_entry.id // from the same pilot
        )
        .map(b => b.registration_number)
        .join(' | ');

      const balloonCoreData = {
        'immatriculation ballon': balloon.registration_number,
        'autres ballons': otherBalloons,
        'zone': this.bsClass.getZoneClass(+balloon.zone),
        'CeN': this.getStatusLabel(balloon.cen_status),
        'assurance': this.getStatusLabel(balloon.insurance_contract_status),
        'total dû': balloon.amount_due + '€',
        'total payé': balloon.amount_paid + '€',
        'total restant': (balloon.amount_due - balloon.amount_paid) + '€',
        'formule 10 jours': balloon.formula.id === 1 ? '1' : '',
        '1er week-end': balloon.formula.id === 2 ? '1' : '',
        '2ème week-end': balloon.formula.id === 3 ? '1' : '',
        'nombre d\'équipiers': balloon.team_members.length,
      };

      for (const event of balloon.competitions) {
        balloonCoreData[event.label_fr] = 1;
      }

      if (pilotSquare !== undefined) {
        balloonCoreData['nombre emplacements pilot\'s square'] = pilotSquare.nb_campsites,
          balloonCoreData['nombre personnes pilot\'s square'] = pilotSquare.nb_people;

        const crawlDate = new Date(this.configurationService.getEdition().start); // clone
        const end = this.configurationService.getEdition().end;
        while (crawlDate <= end) {
          const dateObject = crawlDate;
          const theDate = this.datePipe.transform(dateObject, 'dd/MM');
          const checkedDate = this.datePipe.transform(dateObject, 'yyyy-MM-dd');
          balloonCoreData[theDate] = pilotSquare.dates.some(x => x === checkedDate) ? '1' : '';
          crawlDate.setDate(crawlDate.getDate() + 1);
        }
      }


      for (const jacketOrder of balloon.jackets) {
        for (const jacket of jacketOrder.sizes) {
          for (const size in jacket) {
            if (jacket.hasOwnProperty(size)) {
              if (!balloonCoreData.hasOwnProperty(size)) {
                balloonCoreData[size] = 0;
              }
              balloonCoreData[size] += jacket[size];
            }
          }
        }
      }

      const pilotData = {
        'type': 'pilote',
        'prénom': balloon.pilot_entry.pilot.user.first_name,
        'nom': balloon.pilot_entry.pilot.user.last_name,
        'sexe': balloon.pilot_entry.pilot.user.gender === 1 ? 'Homme' : 'Femme',
        'date de naissance': balloon.pilot_entry.pilot.birth_date,
        'ville de naissance': balloon.pilot_entry.pilot.birth_city,
        'pays de naissance': this.countriesList[balloon.pilot_entry.pilot.birth_country],
        'première participation': balloon.pilot_entry.first_participation !== undefined
          ? (balloon.pilot_entry.first_participation ? 'Oui' : 'Non') : '',
        'adresse': balloon.pilot_entry.pilot.address.replace(new RegExp(/\n|\r/, 'g'), ' '),
        'code postal': balloon.pilot_entry.pilot.zip_code,
        'ville': balloon.pilot_entry.pilot.city,
        'pays': this.countriesList[balloon.pilot_entry.pilot.country],
        'téléphone fixe': balloon.pilot_entry.pilot.phone1,
        'téléphone mobile': balloon.pilot_entry.pilot.phone2,
        'email': balloon.pilot_entry.pilot.user.email,
        'licence': this.getStatusLabel(balloon.pilot_entry.licence_status),
        'certificat médical': this.getStatusLabel(balloon.pilot_entry.medical_certificate_status),
        'carnet de vol': this.getStatusLabel(balloon.pilot_entry.logbook_status),
        'arrivée': balloon.pilot_entry.arrival,
        'départ': balloon.pilot_entry.departure
      };

      exportData.push({ ...model, ...balloonCoreData, ...pilotData });

      for (const copilot of balloon.copilots_entries) {
        const copilotData = {
          'type': 'pilote additionnel',
          'prénom': copilot.pilot.user.first_name,
          'nom': copilot.pilot.user.last_name,
          'sexe': copilot.pilot.user.gender === 1 ? 'Homme' : 'Femme',
          'date de naissance': copilot.pilot.birth_date,
          'ville de naissance': copilot.pilot.birth_city,
          'pays de naissance': this.countriesList[copilot.pilot.birth_country],
          'première participation': copilot.first_participation !== undefined ? (copilot.first_participation ? 'Oui' : 'Non') : '',
          'adresse': copilot.pilot.address,
          'code postal': copilot.pilot.zip_code,
          'ville': copilot.pilot.city,
          'pays': this.countriesList[copilot.pilot.country],
          'téléphone fixe': copilot.pilot.phone1,
          'téléphone mobile': copilot.pilot.phone2,
          'email': copilot.pilot.user.email,
          'licence': this.getStatusLabel(copilot.licence_status),
          'certificat médical': this.getStatusLabel(copilot.medical_certificate_status),
          'carnet de vol': this.getStatusLabel(copilot.logbook_status),
          'arrivée': copilot.arrival,
          'départ': copilot.departure
        };

        exportData.push({ ...model, ...balloonCoreData, ...copilotData });
      }
    }

    this.ng2Csv.download(
      exportData,
      'pilots.csv'
    );
  }

  makePilotCsvModel() {
    const firstFields = {
      'type': '',
      'prénom': '',
      'nom': '',
      'sexe': '',
      'date de naissance': '',
      'ville de naissance': '',
      'pays de naissance': '',
      'première participation': '',
      'adresse': '',
      'code postal': '',
      'ville': '',
      'pays': '',
      'téléphone fixe': '',
      'téléphone mobile': '',
      'email': '',
      'immatriculation ballon': '',
      'autres ballons': '',
      'zone': '',
      'licence': '',
      'certificat médical': '',
      'carnet de vol': '',
      'CeN': '',
      'assurance': '',
      'total dû': '',
      'total payé': '',
      'total restant': '',
      'nombre emplacements pilot\'s square': '',
      'nombre personnes pilot\'s square': ''
    };

    const crawlDate = new Date(this.configurationService.getEdition().start); // clone
    const end = this.configurationService.getEdition().end;
    while (crawlDate <= end) {
      const theDate = this.datePipe.transform(crawlDate, 'dd/MM');
      firstFields[theDate] = '';
      crawlDate.setDate(crawlDate.getDate() + 1);
    }

    for (const event of this.competitionsList) {
      firstFields[event.label_fr] = '';
    }

    const remainingFields = {
      'arrivée': '',
      'départ': '',
      'formule 10 jours': '',
      '1er week-end': '',
      '2ème week-end': '',
      'nombre d\'équipiers': '',
    };

    const model = { ...firstFields, ...remainingFields };

    for (const size of this.jacketSizes) {
      model[size] = '';
    }

    return model;
  }

  getLabel(object) {
    if (object.hasOwnProperty('label_fr')) {
      return object.label_fr;
    }
    return object.label_en;
  }

  displayAdditionalPaymentBadge(balloon: ApiBalloonRegistration) {
    if (balloon.payments.length === 0) {
      return false;
    }
    if (balloon.payments[0].status === 'waiting') {
      return false;
    }
    return balloon.amount_due > balloon.amount_paid;
  }

  getAllJacketsCount(balloon: ApiBalloonRegistration) {
    const jackets = balloon.jackets;
    const sizesCounts = [];
    for (const order of jackets) {
      for (const i in order.sizes) {
        if (order.sizes.hasOwnProperty(i)) {
          sizesCounts.push(order.sizes[i]);
        }
      }
    }

    let reducedCounts = {};
    if (sizesCounts.length !== 0) {
      reducedCounts = sizesCounts.filter(val => val !== undefined).reduce((accumulator = {}, currentValue) => {
        for (const i in currentValue) {
          if (currentValue.hasOwnProperty(i)) {
            if (!accumulator.hasOwnProperty(i)) {
              accumulator[i] = 0;
            }
            accumulator[i] += currentValue[i];
          }
        }
      });
    }


    return reducedCounts;
  }

  /*
  * Returns the pilots squares given the format [{begin: 2023-07-21, end: 2023-07-30}]
  */
  getSquareDatesGroups(pilotSquare): { begin: string, end: string }[] {
    const dateGroups = [];
    let previousDate = new Date(pilotSquare.dates[0]);
    let currentDateGroup = { begin: null, end: null };

    for (const i in pilotSquare.dates) {
      if (pilotSquare.dates.hasOwnProperty(i)) {

        const currentDate = new Date(pilotSquare.dates[i]);

        // get the current day "yesterday" to check if it is in the registered dates
        const dayBeforeCurrentDate = new Date(currentDate.getTime()); // set with new to prevent shared memory allocation of the 2 vars
        dayBeforeCurrentDate.setDate(dayBeforeCurrentDate.getDate() - 1); // remove a day

        // convert dates to same format string to prevent errors with hours set in dates
        const dayBeforeCurrentDateString = this.datePipe.transform(dayBeforeCurrentDate, 'yyyy-MM-dd');
        const previousDateString = this.datePipe.transform(previousDate, 'yyyy-MM-dd');

        if (+i === 0) { // init the first dateGroup
          currentDateGroup = { begin: currentDate, end: null };
        } else if (dayBeforeCurrentDateString !== previousDateString) {
          // end the date streak because today is not the day following the current streak
          currentDateGroup.end = new Date(previousDate.getTime());
          dateGroups.push(currentDateGroup);
          currentDateGroup = { begin: currentDate, end: null };
        }

        if (+i === pilotSquare.dates.length - 1) { // last entry, close the current dateGroup
          currentDateGroup.end = currentDate;
          dateGroups.push(currentDateGroup);
        }

        previousDate = new Date(currentDate.getTime());
      }
    }

    return dateGroups;
  }

  areEntryDocumentValids(balloon: ApiBalloonRegistration) {
    const missingStatus = "missing";
    const waitingStatus = "waiting";
    const rejectStatus = "rejected";
    const pilotEntry = balloon.pilot_entry as ApiPilotRegistration;

    const statusToVerify = [
      balloon.insurance_contract_status,
      balloon.cen_status,
      pilotEntry.licence_status,
      pilotEntry.medical_certificate_status,
      pilotEntry.logbook_status
    ];

    if (statusToVerify.some((status) => status === rejectStatus || status === missingStatus || status === waitingStatus)) {
      return false;
    }

    for (const copilot of balloon.copilots_entries) {
      let statusCopilotToVerify = [
        copilot.licence_status,
        copilot.medical_certificate_status,
        copilot.logbook_status
      ];

      if (statusCopilotToVerify.some((status) => status === rejectStatus || status === missingStatus || status === waitingStatus)) {
        return false;
      }

    }

    return true;
  }

  sort(field: string) {
    const currentSortField = this.currentSort.field;
    if (field === currentSortField) { // change sort order
      this.currentSort.order = this.currentSort.order === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.currentSort = { field: field, order: 'ASC' };
    }

    this.formatBalloonList();
  }

  formatBalloonList() {
    this.formattedBalloonList = [... this.balloonList];

    this.formattedBalloonList = this.formattedBalloonList.filter(balloon => {
      if (this.filter === '') {
        return true;
      }

      const checkedValuesAgainstFilter = [
        balloon.registration_number,
        balloon.name,
        balloon.pilot_entry.pilot.user.first_name,
        balloon.pilot_entry.pilot.user.last_name,
        balloon.pilot_entry.pilot.zip_code
      ];

      for (const copilot of balloon.copilots_entries) {
        checkedValuesAgainstFilter.push(
          copilot.pilot.user.first_name,
          copilot.pilot.user.last_name,
          copilot.pilot.zip_code
        );
      }

      return checkedValuesAgainstFilter.some(element => element.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1);
    });

    this.formattedBalloonList = this.formattedBalloonList.sort(
      (t1, t2) => {
        const t1Value = this.getNestedObject(t1, this.currentSort.field);
        const t2Value = this.getNestedObject(t2, this.currentSort.field);
        const sortOrder = this.currentSort.order;

        if (t1Value > t2Value) {
          return sortOrder === 'ASC' ? 1 : -1;
        } else if (t1Value < t2Value) {
          return sortOrder === 'ASC' ? -1 : 1;
        }

        // order by ID if the former was the same
        if (t1.id > t2.id) {
          return sortOrder === 'ASC' ? 1 : -1;
        } else {
          return sortOrder === 'ASC' ? -1 : 1;
        }
      }
    );
  }

  getNestedObject(nestedObj, path: string) {
    const pathArr = path.split('.');
    return pathArr.reduce((obj, key) =>
      (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
  }

  onFilterChange() {
    this.formatBalloonList();
  }

  onFilterReset() {
    this.filter = '';
    this.onFilterChange();
  }

  onChangePilotSquareOpen(status: boolean) {
    this.adminService.changePilotSquareStatus(status).subscribe(
      data => {
        this.pilotSquareOpen = data.pilots_square_open;
      },
      error => {
        this.alertService.addAlert('danger', 'Une erreur s\'est produite lors du changement de status du Pilots\' Square');
      }
    );
  }

  private subscribeCountries(lang: Lang) {
    this.langService.getCountriesTranslation(lang).subscribe(
      countriesList => {
        this.countriesList = countriesList;
      }
    );
  }
}
