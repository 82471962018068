import { Pipe, PipeTransform } from '@angular/core';

/**
 * formats a date to the format 5h12
 */
@Pipe({
  name: 'iso-time'
})
export class IsoTimePipe implements PipeTransform {
  transform(date: Date) {

    if (!date) {
      return null;
    }

    const hours = date.getHours().toString();
    const minutes = '' + date.getMinutes().toString().padStart(2, '0');

    return `${hours}h${minutes}`;
  }
}
