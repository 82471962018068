import {
  FormGroup,
  FormArray,
  ValidationErrors,
} from '@angular/forms';

// Because of the limit of 6 people per campsite
export const enoughCampsitesForPeople = (errorKey: string = null) => (group: FormGroup): ValidationErrors | null => {

  const key = errorKey || 'enoughCampsitesForPeople';
  const errorObject = {};
  errorObject[key] = true;

  if (!group || !group.controls) {
    return errorObject;
  }

  const values = group.value;
  const peopleNumber = +values.people;
  const campsiteNumber = +values.campsite;

  let enoughCampsites = true;
  if (campsiteNumber > 0 && peopleNumber > 0) {
    enoughCampsites = peopleNumber / campsiteNumber <= 6;
  } else if (campsiteNumber === 0 && peopleNumber > 0) {
    enoughCampsites = false;
  }

  return enoughCampsites ? null : errorObject;
};

// Because a campsite shouldn't be empty of people
export const hasAtLeastOnePersonPerCampsite = (errorKey: string = null) => (group: FormGroup): ValidationErrors | null => {

  const key = errorKey || 'atLeastOnePersonPerCampsite';
  const errorObject = {};
  errorObject[key] = true;

  if (!group || !group.controls) {
    return errorObject;
  }

  const values = group.value;
  const peopleNumber = +values.people;
  const campsiteNumber = +values.campsite;

  const atLeastOnePersonPerCampsite = peopleNumber >= campsiteNumber;

  return atLeastOnePersonPerCampsite ? null : errorObject;
};

// to prevent half completed registration
export const hasAtLeastADateIfCampsite = (errorKey: string = null) => (group: FormGroup): ValidationErrors | null => {
  const key = errorKey || 'atLeastADateIfCampsite';
  const errorObject = {};
  errorObject[key] = true;

  if (!group || !group.controls) {
    return errorObject;
  }

  const values = group.value;
  const campsiteNumber = +values.campsite;
  const dates = group.get('dates') as FormArray;

  let atLeastADateIfCampsite = true;
  if (campsiteNumber > 0) {
    atLeastADateIfCampsite = false;
    for (const date of dates.controls || []) {
      // either the user chose a date, or there are disabled dates, which are already paid ones
      if (date.value.isChecked || date.disabled) {
        atLeastADateIfCampsite = true;
        break;
      }
    }
  }

  return atLeastADateIfCampsite ? null : errorObject;
};

// to prevent half completed registration
export const hasAtLeastACampsiteIfDate = (errorKey: string = null) => (group: FormGroup): ValidationErrors | null => {

  const key = errorKey || 'atLeastACampsiteIfDate';
  const errorObject = {};
  errorObject[key] = true;

  if (!group || !group.controls) {
    return errorObject;
  }

  let atLeastACampsiteIfDate = true;

  const values = group.value;
  const hasADate = values.dates && values.dates.some(date => date.isChecked === true);

  if (hasADate) {
    const campsiteNumber = +values.campsite;
    if (campsiteNumber === 0) {
      atLeastACampsiteIfDate = false;
    }
  }

  return atLeastACampsiteIfDate ? null : errorObject;
};
