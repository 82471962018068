import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {saveAs} from 'file-saver/FileSaver';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ReCaptcha2Component} from 'ngx-captcha';
import {Observable, Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../auth/auth.service';
import {LangService} from '../../core/lang/lang.service';
import {FormControlDisplayHelperService} from '../../shared/form-control-display-helper.service';
import {atLeastOne} from '../../shared/validators/at-least-one.validator';
import {dateInterval} from '../../shared/validators/date-interval.validator';
import {equals, equalTo} from '../../shared/validators/equals.validator';
import {PilotRegistration} from './models/pilot-registration';
import {PilotRegistrationService} from './pilot-registration.service';
import {PilotTransformerService} from './pilot-transformer.service';
import {oldPasswordSetIfNewOneSet} from './validators/password.validators';
import {Dictionary} from '../../shared/types';
import {Lang} from '../../core/lang/lang.type';
import {ApiPilotRegistration} from './models/apiPilot';
import {ConfigurationService} from 'src/app/core/configuration.service';

@Component({
  selector: 'app-pilot-registration',
  templateUrl: './pilot-registration.component.html',
  styleUrls: ['./pilot-registration.component.scss']
})
export class PilotRegistrationComponent implements OnInit, OnDestroy {
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  roleSub: Subscription;
  pilotSub: Subscription;
  pilotForm: FormGroup;
  isFormSubmitted = false;
  pilotReg: PilotRegistration;
  fieldsets;
  countriesList: Dictionary = {};
  arrivalMinDate: Date;
  departureMaxDate: Date;
  filesStatuses = {
    licence: null,
    medicalCertificate: null,
    flightLog: null
  };
  downloadUrl = environment.apiUrl;
  modalRef: BsModalRef;
  currentFileType: string = null;
  displayOldPassword = false;
  isCollapsed = true;

  recaptchaSiteKey = '6Lf4Q3AUAAAAAOOtNmfSkCF1vwIOa8EgWH4Gn0Jk';
  recaptchaPrivateKey = '6Lf4Q3AUAAAAAEucP2XU1v4Ta_ANdZDt2Zy6PD_X';

  // those labels are attainable only by admin.
  // given it is easier to keep those in french, translation is not provided here
  fileTypeLabels = {
    licence: 'Licence du pilote',
    medicalCertificate: 'Certificat médical',
    flightLog: 'Carnet de vol'
  };

  ngOnInit() {
    this.initForm();

    const currentlang = this.langService.getCurrentLang();
    this.subscribeCountries(currentlang);

    this.translate.onLangChange.subscribe(
      lang => this.subscribeCountries(lang.lang)
    );

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      let apiRegistration: Observable<ApiPilotRegistration>;
      if (paramMap.has('pilotId')) {
        const pilotId = +paramMap.get('pilotId');
        apiRegistration = this.pilotRegistrationService.getRegistration(pilotId);
      } else if (this.authService.getRole() === 'pilot') {
        apiRegistration = this.pilotRegistrationService.getOwnRegistration();
      }

      if (apiRegistration) {
        apiRegistration.subscribe(pilot => this.managePilotFromApi(pilot));
      }

      this.roleSub = this.authService.getRoleListener().subscribe(role => {
        if (paramMap.has('pilotId') && role === 'anonymous') {
          this.router.navigate(['/pilot']);
        }
      });

      this.pilotSub = this.pilotRegistrationService.getCurrentPilotListener().subscribe(pilot => {
        if (pilot !== null) {
          this.managePilotFromApi(pilot);
        }
      });
    });
  }

  manageEditFieldsForPilot() {
    this.pilotForm.controls.personalData.disable();
    if (this.pilotForm.controls.personalData instanceof FormGroup) {
      this.pilotForm.controls.personalData.controls.firstParticipation.enable();
    }
  }

  ngOnDestroy() {
    if (this.roleSub) {
      this.roleSub.unsubscribe();
    }

    if (this.pilotSub) {
      this.pilotSub.unsubscribe();
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private pilotRegistrationService: PilotRegistrationService,
    private configurationService: ConfigurationService,
    private modalService: BsModalService,
    private router: Router,
    private transformer: PilotTransformerService,
    public route: ActivatedRoute,
    public langService: LangService,
    public displayHelper: FormControlDisplayHelperService,
    public authService: AuthService,
  ) {
    this.arrivalMinDate = configurationService.getEdition().start;

    const endEdition = configurationService.getEdition().end;
    this.departureMaxDate = new Date(endEdition);
    this.departureMaxDate.setDate(this.departureMaxDate.getDate() + 1);
  }

  initForm() {
    const optionalInternationalPhoneRegex = /^(\+?(\d[ \.\-]?){5,14}\d)?$/;

    this.pilotForm = this.formBuilder.group({
      personalData: this.formBuilder.group({
        name: new FormControl('', Validators.required),
        forname: new FormControl('', Validators.required),
        sex: new FormControl('', Validators.required),
        nationality: new FormControl('', Validators.required),
        birthDate: new FormControl('', Validators.required),
        birthCity: new FormControl('', Validators.required),
        birthCountry: new FormControl('', Validators.required),
        firstParticipation: new FormControl('', Validators.required)
      }),
      licence: this.formBuilder.group({
        number: new FormControl('', Validators.required),
        delivery: new FormControl('', Validators.required),
        flightHours: new FormControl('', Validators.required),
        licenceCountry: new FormControl('', Validators.required)
      }),
      coordinates: this.formBuilder.group({
        address: new FormControl('', Validators.required),
        zipCode: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
        country: new FormControl('', Validators.required)
      }),
      phones: this.formBuilder.group({
        phone: new FormControl('', Validators.pattern(optionalInternationalPhoneRegex)),
        cellphone: new FormControl('', Validators.pattern(optionalInternationalPhoneRegex))
      }),
      connectionData: this.formBuilder.group({
        email: new FormControl('', Validators.required),
        emailConfirmation: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
        passwordConfirmation: new FormControl('', Validators.required)
      }),
      arrivalDeparture: this.formBuilder.group({
        arrival: new FormControl('', [Validators.required, dateInterval]),
        departure: new FormControl('', [Validators.required, dateInterval])
      }),
      pilotFiles: this.formBuilder.group({
        licence: new FormControl(''),
        licenceInput: new FormControl(''),
        medicalCertificate: new FormControl(''),
        medicalCertificateInput: new FormControl(''),
        flightLog: new FormControl(''),
        flightLogInput: new FormControl('')
      }),
      commitment: this.formBuilder.group({
        isAccepted: new FormControl('', [Validators.requiredTrue]),
        receiveNewsletter: new FormControl('')
      }),
      recaptcha: new FormControl('', [Validators.required]),
      id: new FormControl('')
    });

    this.pilotForm.controls.phones.setValidators(atLeastOne(Validators.required, ['phone', 'cellphone']));
    this.pilotForm.controls.connectionData.setValidators([
      equals(['email', 'emailConfirmation'], 'emailsEquals'),
      equals(['password', 'passwordConfirmation'], 'passwordsEquals'),
    ]);

    if (this.authService.getRole() !== 'anonymous') {
      this.pilotForm.get('recaptcha').setValidators([]);
    }
  }

  onSubmitForm() {
    this.isFormSubmitted = true;
    if (!this.pilotForm.valid) {
      return;
    }

    if (this.pilotForm.value.id) {
      this.pilotRegistrationService.updateRegistration(this.pilotForm.value.id, this.pilotForm);
    } else {
      this.pilotRegistrationService.addRegistration(this.pilotForm);
    }
  }

  getPilotSmiley() {
    let smiley = '';

    if (this.pilotReg !== undefined && this.pilotReg.id) {
      smiley = 'smile';

      for (const file in this.filesStatuses) {
        if (this.filesStatuses.hasOwnProperty(file) && this.filesStatuses[file] !== 'valid') {
          smiley = 'meh';
          break;
        }
      }
    }

    return smiley;
  }

  managePilotFromApi(pilot) {
    this.pilotForm.updateValueAndValidity();
    this.pilotReg = this.transformer.revert(pilot);

    this.setFilesStatuses(pilot);
    this.setFormValues();

    if (this.authService.getRole() === 'pilot') {
      this.manageEditFieldsForPilot();
    }

    const connectionDataGroup = this.pilotForm.get('connectionData') as FormGroup;
    for (const controlName in connectionDataGroup.controls) {
      if (connectionDataGroup.controls.hasOwnProperty(controlName)) {
        const control = connectionDataGroup.get(controlName);
        control.setValidators([]);
        control.updateValueAndValidity();
      }
    }

    connectionDataGroup.setValidators([
      equalTo('emailConfirmation', 'email'),
      equals(['password', 'passwordConfirmation'], 'passwordsEquals'),
    ]);
    connectionDataGroup.updateValueAndValidity();
  }

  setFilesStatuses(pilot) {
    this.filesStatuses.licence = pilot.licence_status;
    this.filesStatuses.medicalCertificate = pilot.medical_certificate_status;
    this.filesStatuses.flightLog = pilot.logbook_status;

    const filesStatusesObj = {
      'licence': this.filesStatuses.licence,
      'medicalCertificate': this.filesStatuses.medicalCertificate,
      'flightLog': this.filesStatuses.flightLog
    };

    /**
     * Disable only input when document status is valid
     */
    if (this.authService.getRole() === 'pilot') {
      for (const key in filesStatusesObj) {
        if (filesStatusesObj[key] !== 'valid') {
          this.pilotForm.get(`pilotFiles.${key}Input`).enable();
        } else {
          this.pilotForm.get(`pilotFiles.${key}Input`).disable();
        }
      }
    }
  }

  setFormValues() {
    const formValues = { ... this.pilotReg };
    this.pilotForm.patchValue(formValues);
    this.pilotForm.updateValueAndValidity();

    if (this.authService.getRole() === 'pilot') {
      const connectionFormGroup = this.pilotForm.get('connectionData') as FormGroup;
      connectionFormGroup.addControl('oldPassword', new FormControl(''));
      this.pilotForm.controls.connectionData.setValidators([
        equalTo('emailConfirmation', 'email'),
        equals(['password', 'passwordConfirmation'], 'passwordsEquals'),
        oldPasswordSetIfNewOneSet()
      ]);
      this.displayOldPassword = true;
    }
  }

  onFileChange(event, control: AbstractControl) {
    const file = (event.target as HTMLInputElement).files[0];
    control.patchValue(file);
    control.updateValueAndValidity();
  }

  displayFileName(controlName: string): string {
    const controlValue = this.pilotForm.controls.pilotFiles.get(controlName).value;
    if (controlValue instanceof File) {
      return controlValue.name;
    } else {
      return controlValue;
    }
  }

  onFileClick(fileType: string) {
    if (this.authService.getRole() !== 'anonymous') {
      if (this.filesStatuses[fileType] !== 'missing') {
        this.pilotRegistrationService.fileDownload(this.pilotReg.id, fileType).subscribe(
          response => {
            saveAs(response.body, this.displayFileName(fileType));
          }
        );
      }
    }
  }

  onFileValidate(fileType: string) {
    this.onFileStatusChange(fileType, 'validate');
  }

  onFileReject(fileType: string) {
    this.onFileStatusChange(fileType, 'reject');
  }

  onFileStatusChange(fileType: string, action: string) {
    if (this.authService.getRole() !== 'admin') {
      return;
    }
    this.pilotRegistrationService.changeFileStatus(this.pilotReg.id, fileType, action)
      .subscribe(pilot => this.setFilesStatuses(pilot));
  }

  getFileStatusClass(fileType: string) {
    const status = this.filesStatuses[fileType];
    if (status === 'rejected') {
      return 'invalid';
    }
    return status;
  }

  getUrlForFileType(fileType: string): string {
    const fileTypeConversion = {
      licence: 'licence',
      medicalCertificate: 'medical-certificate',
      flightLog: 'logbook'
    };

    return this.downloadUrl + '/pilots/' + this.pilotReg.id + '/download/' + fileTypeConversion[fileType];
  }

  openModal(template: TemplateRef<any>, fileType: string) {
    this.modalRef = this.modalService.show(template);
    this.currentFileType = fileType;
  }

  getFileTypeLabel(fileType): string {
    return this.fileTypeLabels[fileType];
  }

  private subscribeCountries(lang: Lang) {
    this.langService.getCountriesTranslation(lang).subscribe(
      countriesList => {
        this.countriesList = countriesList;
      }
    );
  }
}

