import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormControlDisplayHelperService } from '../../shared/form-control-display-helper.service';
import { LangService } from '../../core/lang/lang.service';
import { TranslateService } from '@ngx-translate/core';
import { BalloonRegistration } from './models/balloon-registration.interface';
import { BalloonRegistrationService } from './balloon-registration.service';
import { mergeMap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import {
  enoughCampsitesForPeople,
  hasAtLeastADateIfCampsite,
  hasAtLeastOnePersonPerCampsite,
  hasAtLeastACampsiteIfDate
} from './validators/pilot-square.validators';
import { BalloonCalculationService } from './balloon-calculation.service';
import { environment } from '../../../environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { saveAs } from 'file-saver/FileSaver';
import { BalloonTransformerService } from './balloon-transformer.service';
import { AlertService } from '../../core/alert/alert.service';
import { DisplayBsClassService } from '../../shared/display-bs-class.service';
import { ConfigurationService } from '../../core/configuration.service';
import { atLeastOne } from '../../shared/validators/at-least-one.validator';
import { Formula } from '../../core/models/formula.interface';
import { PaymentService } from '../../payment/payment.service';
import { DateService } from 'src/app/core/date.service';
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";

@Component({
  selector: 'app-balloon-registration',
  templateUrl: './balloon-registration.component.html',
  styleUrls: ['./balloon-registration.component.scss'],
})
export class BalloonRegistrationComponent implements OnInit, OnDestroy {
  roleSubject: Subscription;
  balloonForm: FormGroup;
  adminForm: FormGroup;
  isFormSubmitted = false;
  balloonReg: BalloonRegistration = {};
  balloonRegSub: Subscription;
  fieldsets;
  countriesList = {};
  filesStatuses = {
    cen: null,
    insuranceCertificate: null
  };
  now = this.dateService.today;
  formulas = this.configuration.getFormulas();
  copilots = [];
  competitionsList = this.configuration.getCompetitions();
  jacketSizes = this.configuration.getSizes();
  action = '';
  downloadUrl = environment.apiUrl;
  modalRef: BsModalRef;
  currentFileType: string = null;

  asyncSelected: string;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  searchCopilot: Observable<any>;
  alreadyPaidElements = {
    jackets: [],
    teamMembers: [], // will count the first 3 also, even if not paid, to know where to start
    pilotSquareCampsites: 0,
    pilotSquareDates: [],
    formula: null,
    formulaPrice: 0
  };

  fileTypeLabels = {
    cen: 'CEN',
    insuranceCertificate: 'Certificat d\'assurance'
  };
  newBalloonsOpen: boolean;
  pilotSquareOpen = true;
  teamMembersOpen = true;
  edition = this.configuration.getEdition();
  configManucfacturerDatePicker: Partial<BsDatepickerConfig> = {dateInputFormat: 'Y'};

  constructor(
    private formBuilder: FormBuilder,
    public displayHelper: FormControlDisplayHelperService,
    public authService: AuthService,
    private translate: TranslateService,
    private langService: LangService,
    private balloonRegistrationService: BalloonRegistrationService,
    public route: ActivatedRoute,
    public router: Router,
    private datePipe: DatePipe,
    public calculation: BalloonCalculationService,
    private modalService: BsModalService,
    private transformer: BalloonTransformerService,
    private alertService: AlertService,
    public bsClass: DisplayBsClassService,
    private configuration: ConfigurationService,
    private dateService: DateService,
    private paymentService: PaymentService
  ) {
    this.searchCopilot = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.asyncSelected);
    })
      .pipe(
        mergeMap((search: string) => {
          const searchTerm = this.balloonForm.get('copilotSearch').value;
          return this.balloonRegistrationService.copilotSearch(searchTerm);
        }
        )
      );

  }


  ngOnInit() {
    this.newBalloonsOpen = this.dateService.today < this.edition.additional_balloon_until;
    this.manageCountriesLists();
    this.initForm();
    this.disableLockedFields();

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.balloonForm.enable();
      if (paramMap.has('balloonId')) {
        const balloonId = +paramMap.get('balloonId');
        this.balloonRegistrationService.getRegistration(balloonId).subscribe(
          balloon => this.manageBalloonFromApi(balloon),
          error => {
            this.alertService.addAlert(
              'danger',
              this.translate.instant('balloon.An error occurred while trying to access the existing balloon')
            );
            this.router.navigate(['/balloon/']);
          }
        );
        this.balloonRegSub = this.balloonRegistrationService.getCurrentBalloonsListener().subscribe(
          balloons => {
            return this.manageBalloonFromApi(balloons.find(x => x.id === balloonId));
          }
        );
      }
    });
  }

  private manageCountriesLists() {
    const currentlang = this.langService.getCurrentLang();
    this.langService.getCountriesTranslation(currentlang).subscribe(
      countriesList => {
        this.countriesList = countriesList;
      }
    );

    this.translate.onLangChange.subscribe(
      lang => {
        this.langService.getCountriesTranslation(lang.lang).subscribe(
          countriesList => {
            this.countriesList = countriesList;
          }
        );
      }
    );
  }

  initForm() {
    let defaultPilot = '';
    if (this.authService.getRole() === 'pilot') {
      defaultPilot = this.authService.getUser().pilot_id;
    }

    const formulasControls = {};
    for (const formula of this.configuration.getFormulas()) {
      formulasControls[formula.id] = new FormControl('');
    }

    this.balloonForm = this.formBuilder.group({
      id: new FormControl(''),
      informations: new FormGroup({
        registrationNumber: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required),
        volume: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
        manufacturer: new FormControl('', Validators.required),
        manufacturingDate: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        cdnValidity: new FormControl('', Validators.required),
        flightHours: new FormControl('', Validators.required),
        insuranceCompany: new FormControl('', Validators.required),
        insuranceNumber: new FormControl('', Validators.required),
        insuranceValidity: new FormControl('', Validators.required)
      }),
      balloonFiles: new FormGroup({
        cenInput: new FormControl(''),
        cen: new FormControl(''),
        insuranceCertificateInput: new FormControl(''),
        insuranceCertificate: new FormControl('')
      }),
      formulas: new FormGroup(formulasControls, [atLeastOne(Validators.required)]),
      copilotSearch: new FormControl(''),
      pilot: new FormControl(defaultPilot, Validators.required),
      copilots: new FormArray([]),
      teamMembers: new FormArray([]),
      pilotSquare: new FormArray([this.getPilotSquareControl()]),
      competitions: new FormGroup({}),
      jackets: new FormArray([this.getJacketControl()])
    });

    const firstPilotSquareDatesForm = this.balloonForm.get('pilotSquare.0.dates') as FormArray;
    this.addPilotSquareDatesControls(firstPilotSquareDatesForm);

    const eventFormGroup = this.balloonForm.get('competitions') as FormGroup;
    for (const i in this.competitionsList) {
      if (this.competitionsList.hasOwnProperty(i)) {
        eventFormGroup.addControl(this.competitionsList[i].id.toString(), new FormControl(''));
      }
    }

    if (this.authService.getRole() === 'admin') {
      this.adminForm = new FormGroup({
        paymentStatus: new FormControl(''),
        registrationStatus: new FormControl(''),
        registrationArea: new FormControl(''),
        newPaymentAmount: new FormControl(''),
        newPaymentMethod: new FormControl('')
      });
    }

  }

  onSubmitForm(event) {
    this.isFormSubmitted = true;
    if (!this.balloonForm.valid) {
      return;
    }

    if (this.balloonForm.value.id) {
      this.balloonRegistrationService.updateRegistration(this.balloonForm.value.id, this.balloonForm, this.action);
    } else {
      this.balloonRegistrationService.addRegistration(this.balloonForm, this.action);
    }
  }

  onFormulaChange(formulaId: number) {
    const formulaGroup = this.balloonForm.get('formulas') as FormGroup;
    const formValues = formulaGroup.value;
    for (const i in formValues) {
      if (formValues.hasOwnProperty(i)) {
        formValues[i] = +i === formulaId ? true : '';
      }
    }
    formulaGroup.patchValue(formValues);
  }

  manageBalloonFromApi(balloon) {
    this.copilots = [];
    this.initForm();

    this.balloonReg = this.transformer.revert(balloon);
    this.setFilesStatuses(balloon);
    this.setFormValues();

    if (this.authService.getRole() === 'admin') {
      this.setAdminFormValues();
    }

    if (this.authService.getRole() === 'pilot' && this.isFormValidatedByAdmin()) {
      this.balloonForm.updateValueAndValidity();
    }
  }

  /**
   * Disable these fields if the balloon registration is validate :
   * registrationNumber, type, volume, name, manufacturer, manufacturingDate, description
   */
  disabledFieldsRegistrationValidate() {
    return this.authService.getRole() === 'pilot' && this.isFormValidatedByAdmin();
  }

  getBalloonSmiley() {
    if (this.balloonReg.id === undefined) {
      return '';
    }
    if (this.balloonReg.status !== 'valid') {
      return '';
    }

    let smiley = 'smile';
    for (const file in this.filesStatuses) {
      if (this.filesStatuses.hasOwnProperty(file) && this.filesStatuses[file] !== 'valid') {
        smiley = 'meh';
        break;
      }
    }
    return smiley;
  }

  setAdminFormValues() {
    this.adminForm.get('registrationStatus').patchValue(this.balloonReg.status);
    this.adminForm.get('registrationArea').patchValue(this.balloonReg.zone);
    const payments = this.balloonReg.payments;
    const initialPayment = this.paymentService.getInitialPayment(payments);

    if (initialPayment) {
      this.adminFormPatchPaymentStatus(initialPayment.status);
    }
  }

  adminFormPatchPaymentStatus(status) {
    this.adminForm.get('paymentStatus').patchValue(status);
  }

  setFilesStatuses(balloon) {
    this.filesStatuses.cen = balloon.cen_status;
    this.filesStatuses.insuranceCertificate = balloon.insurance_contract_status;
    if (this.authService.getRole() === 'pilot') {
      if (this.filesStatuses.cen === 'valid') {
        this.balloonForm.get('balloonFiles.cenInput').disable();
      }

      if (this.filesStatuses.insuranceCertificate === 'valid') {
        this.balloonForm.get('balloonFiles.insuranceCertificateInput').disable();
      }
    }
  }

  setFormValues() {
    const formValues = { ... this.balloonReg };

    let setPaidElements = false;
    if (this.balloonReg && this.balloonReg.hasOwnProperty('payments')) {
      const payments = this.balloonReg.payments;
      if (payments !== undefined && payments.hasOwnProperty(0)) {
        const firstPayment = payments[0];
        if (firstPayment.status === 'valid' || firstPayment.status === 'gift') {
          setPaidElements = true;
        }
      }
    }

    this.alreadyPaidElements = {
      jackets: [],
      teamMembers: [], // will count the first 3 also, even if not paid, to know where to start
      pilotSquareCampsites: 0,
      pilotSquareDates: [],
      formula: null,
      formulaPrice: 0
    };

    this.balloonForm.patchValue(formValues);
    this.balloonForm.updateValueAndValidity();
    this.copilots = [];
    for (const copilot of formValues.copilots) {
      this.addCopilotToForm(copilot);
    }

    const teamMembersControl = this.balloonForm.get('teamMembers') as FormArray;
    for (const i in formValues.teamMembers) {
      if (formValues.teamMembers.hasOwnProperty(i)) {
        const member = formValues.teamMembers[i];

        const teamMemberControl = this.getTeamMemberControl();
        teamMemberControl.patchValue(member);

        if (setPaidElements) {
          this.alreadyPaidElements.teamMembers.push(+i);
        }


        teamMembersControl.push(teamMemberControl);
      }
    }

    const jacketControl = this.balloonForm.get('jackets') as FormArray;
    jacketControl.controls.splice(0);
    for (const i in formValues.jackets) {
      if (formValues.jackets.hasOwnProperty(i)) {
        const jacket = formValues.jackets[i];
        const newJacket = this.getJacketControl();
        newJacket.patchValue(jacket);

        if (setPaidElements) {
          this.alreadyPaidElements.jackets.push(+i);
        }
        jacketControl.push(newJacket);
      }
    }


    for (const i in formValues.pilotSquare) {
      if (!formValues.pilotSquare.hasOwnProperty(i)) {
        continue;
      }

      // managed for only 1 order, will need more code if we manage many pilot's square registration in the future
      if (setPaidElements) {
        this.alreadyPaidElements.pilotSquareCampsites = formValues.pilotSquare[i].campsite;
      }
      this.balloonForm.get('pilotSquare.0.campsite')
        .setValidators([
          Validators.min(this.alreadyPaidElements.pilotSquareCampsites)
        ]);

      const squareDates = formValues.pilotSquare[i].dates;
      const squareFormDatesArray = this.balloonForm.get('pilotSquare.' + i + '.dates') as FormArray;

      if (!squareFormDatesArray) {
        continue;
      }

      for (const dateGroup of squareFormDatesArray.controls) {
        if (squareDates.some(x => x === dateGroup.value.date)) {
          dateGroup.get('isChecked').patchValue(true);

          if (setPaidElements && this.authService.getRole() !== 'admin') {
            dateGroup.disable();
            this.alreadyPaidElements.pilotSquareDates.push(dateGroup.value.date);
          }
        }
      }

    }

    if (setPaidElements && this.authService.getRole() !== 'admin') {
      this.alreadyPaidElements.formula = +this.balloonReg.formulaDetails.id;
      const formulaGroup = this.balloonForm.get('formulas') as FormGroup;
      for (const i in formulaGroup.controls) {
        if (formulaGroup.controls.hasOwnProperty(i)) {
          formulaGroup.get(i).disable();
        }
      }
    }

    this.disableLockedFields();
  }

  disableLockedFields() {
    if (this.authService.getRole() === 'admin') {
      return;
    }
    const now = this.dateService.today;
    if (
      now > new Date(this.edition.additional_pilots_square_until)
      || !this.edition.pilots_square_open
    ) {
      this.balloonForm.get('pilotSquare').disable();
      this.pilotSquareOpen = false;
    }

    if (now > this.edition.additional_team_member_until) {
      this.balloonForm.get('teamMembers').disable();
      this.teamMembersOpen = false;
    }
  }

  allowDeletion(element: string, index: any) {
    let isAllowed: boolean;
    switch (element) {
      case 'teamMember':
        isAllowed = !this.alreadyPaidElements.teamMembers.some(x => x === index);
        break;
      case 'jacket':
        isAllowed = !this.alreadyPaidElements.jackets.some(x => x === index);
        break;
      case 'squareDate':
        isAllowed = !this.alreadyPaidElements.pilotSquareDates.some(x => x === index);
        break;
      default:
        break;
    }
    return isAllowed;
  }

  addCopilotToForm(copilotData) {
    const copilotsArray = this.balloonForm.get('copilots') as FormArray;
    const newControl = this.getCopilotControl();
    newControl.patchValue(copilotData);
    newControl.updateValueAndValidity();
    newControl.disable();
    newControl.get('id').enable();

    this.copilots.push(copilotData);
    copilotsArray.push(newControl);
  }

  onFileChange(event, control: AbstractControl) {
    const file = (event.target as HTMLInputElement).files[0];
    control.patchValue(file);
    control.updateValueAndValidity();
  }

  displayFileName(controlName: string): string {
    const controlValue = this.balloonForm.controls.balloonFiles.get(controlName).value;
    if (controlValue instanceof File) {
      return controlValue.name;
    } else {
      return controlValue;
    }
  }

  getElementFromApiLabel(element): string {
    if (this.langService.getCurrentLang() === 'fr') {
      return element.label_fr;
    }
    return element.label_en;
  }

  getEventDetails(event): string {
    const checkedKey = this.langService.getCurrentLang() === 'fr' ? 'details_fr' : 'details_en';
    if (event.hasOwnProperty(checkedKey)) {
      return event[checkedKey];
    }
    return null;
  }

  /**
   * When a copilot is selected from the autocomplete list :
   * add a new control to the balloonForm.copilots
   * add the copilot ID to the control
   * fetch data to HTML for display and removal
   *
   * @param event onClick angular event
   */
  onCopilotSelect(event) {
    const copilotFormData = event.item;
    this.balloonForm.get('copilots').markAsDirty();
    this.addCopilotToForm(copilotFormData);
    this.balloonForm.get('copilotSearch').patchValue('');
  }

  onCopilotRemoval(event, id) {
    const copilotId = this.balloonForm.get('copilots.' + id + '.id').value;
    const copilotsArray = this.balloonForm.get('copilots') as FormArray;
    this.balloonForm.get('copilots').markAsDirty();
    copilotsArray.removeAt(id);
    this.copilots.splice(
      this.copilots.indexOf(
        this.copilots.find(x => x.id === copilotId)
      ),
      1
    );
  }

  onTeamMemberAdd() {
    const teamMembersControl = this.balloonForm.get('teamMembers') as FormArray;
    teamMembersControl.push(this.getTeamMemberControl());
  }

  onTeamMemberRemoval(event, id) {
    const teamMembers = this.balloonForm.get('teamMembers') as FormArray;
    teamMembers.removeAt(id);
  }

  onJacketAdd() {
    const jackets = this.balloonForm.get('jackets') as FormArray;
    jackets.push(this.getJacketControl());
  }

  onJacketRemoval(event, id) {
    const jackets = this.balloonForm.get('jackets') as FormArray;
    jackets.removeAt(id);
  }

  onPilotSquareAdd() {
    const pilotSquareFormArray = this.balloonForm.get('pilotSquare') as FormArray;
    const newPilotSquareGroup = this.getPilotSquareControl();
    this.addPilotSquareDatesControls(newPilotSquareGroup.get('dates') as FormArray);
    pilotSquareFormArray.push(newPilotSquareGroup);
  }

  onPilotSquareRemoval(event, id) {
    const pilotSquareFormArray = this.balloonForm.get('pilotSquare') as FormArray;
    pilotSquareFormArray.removeAt(id);
  }

  getCopilotControl() {
    return new FormGroup({
      id: new FormControl('', Validators.required),
      licenceNumber: new FormControl(''),
      name: new FormControl(''),
      forname: new FormControl('')
    });
  }

  getTeamMemberControl() {
    return new FormGroup({
      name: new FormControl('', Validators.required),
      forname: new FormControl('', Validators.required),
      birthDate: new FormControl('', Validators.required),
      birthCity: new FormControl('', Validators.required),
      birthCountry: new FormControl('', Validators.required)
    });
  }

  getPilotSquareControl() {
    const formGroup = new FormGroup({
      people: new FormControl(''),
      campsite: new FormControl(''),
      dates: new FormArray([])
    });

    formGroup.setValidators([
      enoughCampsitesForPeople(),
      hasAtLeastOnePersonPerCampsite(),
      hasAtLeastADateIfCampsite(),
      hasAtLeastACampsiteIfDate()
    ]);

    return formGroup;
  }

  addPilotSquareDatesControls(formArray: FormArray) {
    const crawlDate = new Date(this.edition.start); // clone date
    const end = this.edition.end;
    while (crawlDate <= end) {
      const theDate = crawlDate;
      formArray.push(
        new FormGroup({
          date: new FormControl(this.datePipe.transform(theDate, 'yyyy-MM-dd')),
          isChecked: new FormControl('')
        })
      );

      crawlDate.setDate(crawlDate.getDate() + 1);
    }
  }

  setAction(action) {
    this.action = action;
  }

  getJacketControl() {
    return new FormGroup({
      size: new FormControl('', Validators.required)
    });
  }

  priceCalculation() {
    return this.jacketCalculation() + this.pilotSquareCalculation() + this.teamMemberCalculation() + this.formulaCalculation();
  }

  toPayPriceCalculation() {
    return this.priceCalculation() - this.getTotalPaidOrGifted();
  }

  formulaCalculation() {
    const formGroup = this.balloonForm.get('formulas') as FormGroup;
    return +this.calculation.formulaCalculationFromForm(formGroup, this.balloonReg);
  }

  teamMemberCalculation() {
    const formArray = this.balloonForm.get('teamMembers') as FormArray;
    return +this.calculation.teamMemberCalculationFromForm(formArray);
  }

  jacketCalculation() {
    const formArray = this.balloonForm.get('jackets') as FormArray;
    return +this.calculation.jacketCalculationFormForm(formArray);
  }

  pilotSquareCalculation() {
    const formArray = this.balloonForm.get('pilotSquare') as FormArray;
    return +this.calculation.pilotSquareCalculationFromForm(formArray);
  }

  onFileClick(fileType: string) {
    if (this.authService.getRole() !== 'anonymous') {
      if (this.filesStatuses[fileType] !== 'missing') {
        this.balloonRegistrationService.fileDownload(this.balloonReg.id, fileType).subscribe(
          response => {
            saveAs(response.body, this.displayFileName(fileType));
          },
          error => {
            this.alertService.addAlert('danger', 'An error occurred while loading the file.');
          }
        );
      }
    }
  }

  onFileValidate(fileType: string) {
    this.onFileStatusChange(fileType, 'validate');
  }

  onFileReject(fileType: string) {
    this.onFileStatusChange(fileType, 'reject');
  }

  onFileStatusChange(fileType: string, action: string) {
    if (this.authService.getRole() !== 'admin') {
      return;
    }
    this.balloonRegistrationService.changeFileStatus(this.balloonReg.id, fileType, action).subscribe(
      balloon => {
        this.setFilesStatuses(balloon);
      },
      error => {
        this.alertService.addAlert('danger', 'An error prevented the file status change.');
      }
    );
  }

  getFileStatusClass(fileType: string): string {
    const status = this.filesStatuses[fileType];
    if (status === 'rejected') {
      return 'invalid';
    }
    return status;
  }

  getUrlForFileType(fileType: string) {
    const fileTypeConversion = {
      cen: 'cen',
      insuranceCertificate: 'insurance-contract',
    };

    return this.downloadUrl + '/balloon/' + this.balloonReg.id + '/download/' + fileTypeConversion[fileType];
  }

  openModal(template: TemplateRef<any>, fileType: string) {
    if (this.filesStatuses[fileType] !== 'missing') {
      this.modalRef = this.modalService.show(template);
      this.currentFileType = fileType;
    }
  }

  onSubmitAdminForm() {
    if (!this.adminForm.valid) {
      return;
    }

    this.balloonRegistrationService.updateRegistrationAdminPart(this.balloonForm.value.id, this.adminForm.value).subscribe(
      balloon => {
        this.alertService.addAlert('success', 'Vos modifications ont été sauvegardées.');
      },
      error => {
        const message = error.error.hasOwnProperty('message') ? error.error.message : 'Une erreur a empêché la mise à jour du ballon.';
        this.alertService.addAlert('danger', message, 0);
      }
    );
  }

  getFileTypeLabel(fileType): string {
    return this.fileTypeLabels[fileType];
  }

  isFormValidatedByAdmin() {
    return this.balloonReg.status === 'valid';
  }

  getPaidTotal() {
    let totalPaid = 0;
    if (this.balloonReg && this.balloonReg.hasOwnProperty('payments')) {
      for (const payment of this.balloonReg.payments) {
        if (payment.status === 'valid' || payment.status === 'gift') {
          totalPaid += +payment.amount;
        }
      }
    }

    return totalPaid;
  }

  hasValidPayment() {
    return this.getPaidTotal() > 0;
  }

  hasAddedPayment() {
    return this.getPaidTotal() < this.priceCalculation();
  }

  areUnpaidAlreadyValidatedInformationsDirty() {
    const elementsDirtyState = [];
    for (const element of ['informations', 'balloonFiles', 'competitions', 'copilots']) {
      elementsDirtyState.push(this.balloonForm.get(element).dirty);
    }

    const teamMembersArray = this.balloonForm.get('teamMembers') as FormArray;
    for (const teamMemberGroup of teamMembersArray.controls) {
      elementsDirtyState.push(teamMemberGroup.dirty);
    }

    const jacketsArray = this.balloonForm.get('jackets') as FormArray;
    for (const jacketGroup of jacketsArray.controls) {
      elementsDirtyState.push(jacketGroup.dirty);
    }

    return elementsDirtyState.some(x => x === true);
  }

  displaySaveButton() {
    return this.displayedButtons().some(x => x === 'save');
  }

  displayFinalizeButton() {
    return this.displayedButtons().some(x => x === 'finalize');
  }

  displayPayButton() {
    return this.displayedButtons().some(x => x === 'pay');
  }

  displaySaveAndPayButton() {
    return this.displayedButtons().some(x => x === 'save & pay');
  }

  displayedButtons() {
    if (this.authService.getRole() === 'admin') {
      return ['save'];
    }
    if (!this.balloonReg.hasOwnProperty('id') || this.balloonReg.status === 'saved') {
      return ['save', 'finalize'];
    } else {
      if (this.mustPay()) {
        if (this.areUnpaidAlreadyValidatedInformationsDirty()) {
          return ['save & pay'];
        } else {
          return ['pay'];
        }
      } else {
        if (this.areUnpaidAlreadyValidatedInformationsDirty() || this.toPayPriceCalculation() <= 0) {
          return ['save'];
        } else {
          return [];
        }
      }
    }
  }

  getPilotSquareControls() {
    const pilotSquareFormGroup = this.balloonForm.get('pilotSquare') as FormArray;
    return pilotSquareFormGroup.controls || [];
  }

  mustPay() {
    if (!this.hasValidPayment() && this.priceCalculation() === 0) {
      return false;
    }

    if (this.hasValidPayment() && this.priceCalculation() <= this.getTotalPaidOrGifted()) {
      return false;
    }

    return true;
  }

  getTotalPaidOrGifted() {
    if (!this.balloonReg.hasOwnProperty('payments') || this.balloonReg.payments.length === 0) {
      return 0;
    }

    let total = 0;
    for (const payment of this.balloonReg.payments) {
      if (payment.status === 'valid' || payment.status === 'gift') {
        total += +payment.amount;
      }
    }

    return total;
  }

  getPilotSquareDateClass(dateGroup): string {
    return dateGroup.disabled ? 'primary' : 'success';
  }

  getFormulaById(formula: number): Formula {
    return this.formulas.find(x => x.id === formula);
  }

  ngOnDestroy() {
    if (typeof this.balloonRegSub !== 'undefined') {
      this.balloonRegSub.unsubscribe();
    }
  }

  isRegistrationEarlyBird(balloonReg): boolean {
    if (balloonReg.id !== undefined) {
      return balloonReg.earlyBird;
    }

    if (this.now < new Date('2022-11-28')) {
      return true;
    }

    return false;
  }
}
