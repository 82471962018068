import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventFileType } from './event-file-type.enum';
import { Event } from '../core/models/event';
import { HttpClient } from '@angular/common/http';
import { Edition } from '../core/models/edition.interface';
import { LangService } from '../core/lang/lang.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(
    private http: HttpClient,
    private langService: LangService
  ) { }

  private get querystring() {
    const locale = this.langService.getRequestLocale();
    return `?lang=${locale}`;
  }

  uploadAeronauticalMap(aeronauticalMapFile: File) {
    const formData = new FormData();
    formData.append('file', aeronauticalMapFile);
    const route = `${environment.apiUrl}/edition/upload/aeronautical-map`;
    return this.http.post<Edition>(route, formData).toPromise();
  }

  getAeronauticalMapUrl(): string {
    return `${environment.apiUrl}/edition/download/aeronautical-map${this.querystring}`;
  }

  publish(event: Event) {
    const route = `${environment.apiUrl}/event/${event.id}`;
    const data = {publish: event.published};
    return this.http.patch<Event>(route, data).toPromise();
  }

  uploadEventFile(event: Event, fileType: EventFileType, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    const route = `${environment.apiUrl}/event/${event.id}/upload/${fileType}`;
    return this.http.post<Event>(route, formData).toPromise();
  }

  removeEventFile(event: Event, fileType: EventFileType) {
    const route = `${environment.apiUrl}/event/${event.id}/delete/${fileType}`;
    return this.http.delete<Event>(route).toPromise();
  }

  getEventFileUrl(event: Event, fileType: EventFileType): string {
    return `${environment.apiUrl}/event/${event.id}/download/${fileType}${this.querystring}`;
  }

}
