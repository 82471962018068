import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth/auth.service';
import { LangService } from './core/lang/lang.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from '../environments/environment';
import {ConfigurationService} from './core/configuration.service';
import { DateService } from './core/date.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  static readonly FULL_PAGE_ROUTES = [
    '/admin/dashboard',
    '/admin/flights'
  ];

  @ViewChild('signedInModal') signedInModal: TemplateRef<any>;
  title = 'app';
  routerSub: Subscription;
  signedInSub: Subscription;
  earlyBird: boolean;

  modalRef: BsModalRef;

  constructor(
    public translate: TranslateService,
    private langService: LangService,
    private authService: AuthService,
    public router:  Router,
    private modalService: BsModalService,
    private dateService: DateService,
    public configurationService: ConfigurationService
  ) {
    translate.setDefaultLang(langService.getCurrentLang());
    translate.use(langService.getCurrentLang());

    if (environment.env === 'production') {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          (<any>window).ga('set', 'page', event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');
        }
      });
    }

    const earlyBirdUntil = this.configurationService.getEdition().early_bird_until;
    const today = this.dateService.today;
    this.earlyBird = today <= earlyBirdUntil;
  }

  isFullPage() {
    return AppComponent.FULL_PAGE_ROUTES.indexOf(this.router.url) !== -1;
  }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    if (!this.isFullPage()) {
      body.classList.add('inscription');
    }

    this.signedInSub = this.authService.getSignedInListener().subscribe(
      () => {
        this.modalRef = this.modalService.show(this.signedInModal,  {class: 'modal-lg'});
      }
    );

    this.routerSub = this.router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          if (this.isFullPage()) {
            body.classList.remove('inscription');
          } else {
            body.classList.add('inscription');
          }
        }
      }
    );

    this.authService.autoAuthUser();
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('inscription');
    this.signedInSub.unsubscribe();
  }

  getContainerClasses() {
    return {
      'container': this.router.url !== '/admin/dashboard',
      'container-fluid': this.router.url === '/admin/dashboard',
      'mt-3': this.router.url === '/admin/dashboard'
    };
  }
}
