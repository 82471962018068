import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http: HttpClient
  ) { }

  registerPayment(balloon: number, method: string, amount: number) {
    const paymentPayload = {
      balloon_entry: {
        id: balloon
      },
      amount: amount,
      method: method
    };

    return this.http.post<any>(
      environment.apiUrl + '/payment',
      paymentPayload
    );
  }

  getPaymentCb(balloon: number) {
    return this.http.get(environment.apiUrl + '/payment-cb/' + balloon);
  }

  getPaymentInfos(paymentId: number) {
    return this.http.get(environment.apiUrl + '/payment/' + paymentId);
  }

  getInitialPayment(payments: any[]) {
    switch (payments.length) {
      case 0:
        return null;
      case 1:
        return payments[0];
      default:
        for (const payment of payments) {
          if (payment.status !== 'canceled' && payment.status !== 'rejected' ) {
            // Return the first payment that is not canceled or rejected
            return payment;
          }
        }
        // If all payments are either canceled or rejected, return the first one.
        return payments[0];
    }
  }
}
