import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControlDisplayHelperService} from '../../../shared/form-control-display-helper.service';
import {AbstractControl, FormGroup} from '@angular/forms';
import {ConfigurationService} from '../../../core/configuration.service';
import {Dictionary} from '../../../shared/types';
import {Lang} from '../../../core/lang/lang.type';
import {LangService} from '../../../core/lang/lang.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../auth/auth.service';
import {PilotRegistrationService} from '../../pilot-registration/pilot-registration.service';
import {ReCaptcha2Component} from 'ngx-captcha';
import {FileStatus} from '../../../constants/file-status.constants';

@Component({
  selector: 'app-create-pilot-informations',
  templateUrl: './create-pilot-informations.component.html',
  styleUrls: []
})
export class CreatePilotInformationsComponent implements OnInit {
  @Input() pilotForm: FormGroup;
  @Output() previousStep: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  isFormSubmitted = false;
  countriesList: Dictionary = {};
  filesStatuses = {
    licence: null,
    medicalCertificate: null,
    flightLog: null
  };

  arrivalMinDate: Date;
  departureMaxDate: Date;
  recaptchaSiteKey = '6Lf4Q3AUAAAAAOOtNmfSkCF1vwIOa8EgWH4Gn0Jk';

  constructor(
    public authService: AuthService,
    private configurationService: ConfigurationService,
    public displayHelper: FormControlDisplayHelperService,
    public langService: LangService,
    private pilotRegistrationService: PilotRegistrationService,
    private translate: TranslateService,
  ) {
    this.arrivalMinDate = configurationService.getEdition().start;

    const endEdition = configurationService.getEdition().end;
    this.departureMaxDate = new Date(endEdition);
    this.departureMaxDate.setDate(this.departureMaxDate.getDate() + 1);
  }

  ngOnInit() {
    const currentlang = this.langService.getCurrentLang();
    this.subscribeCountries(currentlang);

    this.translate.onLangChange.subscribe(
      lang => this.subscribeCountries(lang.lang)
    );
  }

  displayFileName(controlName: string): string {
    const controlValue = this.pilotForm.controls.pilotFiles.get(controlName).value;
    if (controlValue instanceof File) {
      return controlValue.name;
    } else {
      return controlValue;
    }
  }

  getFileStatusClass(fileType: string) {
    const status = this.filesStatuses[fileType];
    if (status === FileStatus.rejected) {
      return 'invalid';
    }
    return status;
  }

  onSubmit() {
    this.isFormSubmitted = true;
    if (!this.pilotForm.valid) {
      return;
    }

    this.pilotRegistrationService.addRegistration(this.pilotForm);
  }

  onFileChange(event, control: AbstractControl) {
    const file = (event.target as HTMLInputElement).files[0];
    control.patchValue(file);
    control.updateValueAndValidity();
  }

  private subscribeCountries(lang: Lang) {
    this.langService.getCountriesTranslation(lang).subscribe(
      countriesList => {
        this.countriesList = countriesList;
      }
    );
  }
}
