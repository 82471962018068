import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Login } from '../models/login';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  logged: Subscription;
  modalRef: BsModalRef;

  forgottenPasswordForm = new FormGroup({
    email: new FormControl('', Validators.required)
  });
  forgottenPasswordSent = false;
  forgottenPasswordError = false;

  constructor(
    public authService: AuthService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
  }

  onLogin(form: NgForm) {
    if (form.invalid) {
      return;
    }

    const loginData: Login = {
      email: form.value.email,
      password: form.value.password
    };

    this.authService.login(loginData);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  onPasswordReset() {
    this.authService.forgottenPassword(this.forgottenPasswordForm.get('email').value).subscribe(
      ok => {
        this.forgottenPasswordSent = true;
      },
      ko => {
        this.forgottenPasswordError = true;
      }
    );
  }

}
