import { Injectable } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormControlDisplayHelperService {

  constructor() { }

  inputClasses(control: AbstractControl, submitted: boolean) {
    return {
      'form-control': true,
      'is-invalid': this.displayInvalid(control, submitted),
      'is-valid': this.isTouchedAndValid(control)
    };
  }

  checkboxClasses(control: AbstractControl) {
    return {
      'checkbox-switch': true,
      'checkbox-switch-left': true,
      'checkbox-color-success': !control.disabled,
      'checkbox-color-primary': control.disabled
    };
  }

  displayInvalid(control: AbstractControl, submitted: boolean) {
    return this.isTouchedAndInvalid(control) || (submitted && this.isInvalid(control));
  }

  isTouched(control: AbstractControl) {
    return control.touched;
  }

  isValid(control: AbstractControl) {
    return control.valid;
  }

  isInvalid(control: AbstractControl) {
    return control.invalid;
  }

  isTouchedAndValid(control: AbstractControl) {
    return (control.touched && control.valid);
  }

  isTouchedAndInvalid(control: AbstractControl) {
    return (control.touched && control.invalid);
  }

  displayHasAtLeastOnePhone(formGroup: AbstractControl, isFormSubmitted: boolean) {
    const notEvenOne = formGroup.getError('atLeastOnePhone') === undefined;
    const fieldTouched = this.isTouched(formGroup.get('phone')) || this.isTouched(formGroup.get('cellphone'));
    return notEvenOne && (fieldTouched || isFormSubmitted);
  }

  displaySamePassword(formGroup: AbstractControl, isFormSubmitted: boolean) {
    const bothAreTouched = this.bothPasswordsTouched(formGroup);
    const areDifferent = formGroup.getError('passwordsEquals');

    return areDifferent && (bothAreTouched || isFormSubmitted);
  }

  displaySameEmail(formGroup: AbstractControl, isFormSubmitted: boolean) {
    const bothAreTouched = this.bothEmailsTouched(formGroup);
    const areDifferent = formGroup.getError('emailsEquals');

    return areDifferent && (bothAreTouched || isFormSubmitted);
  }

  bothEmailsTouched(formGroup: AbstractControl) {
    return this.allFieldsTouched(formGroup, ['email', 'emailConfirmation']);
  }

  bothPasswordsTouched(formGroup: AbstractControl) {
    return this.allFieldsTouched(formGroup, ['password', 'passwordConfirmation']);
  }

  allFieldsTouched(formGroup: AbstractControl, fields = []) {
    for (const i in fields) {
      if (!fields.hasOwnProperty(i) || !formGroup.get(fields[i]).touched) {
        return false;
      }
    }

    return true;
  }
}
