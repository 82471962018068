import {
  FormGroup,
  ValidationErrors,
} from '@angular/forms';

export const equals = (controls: string[] = null, errorKey: string = null) => (group: FormGroup): ValidationErrors | null => {
  if (!controls) {
    controls = Object.keys(group.controls);
  }

  const key = errorKey || 'equals';
  const errorObject = {};
  errorObject[key] = true;

  if (!group || !group.controls) {
    return errorObject;
  }

  let areEquals = true;
  const value = group.controls[controls[0]].value;
  // check if value is the same for all formControls
  for (let i = 1; i < controls.length; i++) {
    if (value !== group.controls[controls[i]].value) {
      areEquals = false;
      break;
    }
  }

  return areEquals ? null : errorObject;
};

export const equalTo = (verified: string, subject: string, canBeNull: boolean = true, errorKey: string = null) =>
  (group: FormGroup): ValidationErrors | null => {
    const key = errorKey || 'equalTo';
    const errorObject = {};
    errorObject[key] = true;

    if (!group || !group.controls) {
      return errorObject;
    }

    const verifiedValue = group.get(verified).value;

    let isEqualTo = verifiedValue === group.get(subject).value;
    if (!isEqualTo && canBeNull) {
      isEqualTo = verifiedValue === '';
    }

    return isEqualTo ? null : errorObject;
  };
