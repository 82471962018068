import {
  FormGroup,
  ValidationErrors,
} from '@angular/forms';

export const oldPasswordSetIfNewOneSet = (errorKey: string = null) => (group: FormGroup): ValidationErrors | null => {

  const key = errorKey || 'oldPasswordSetIfNewOneSet';
  const errorObject = {};
  errorObject[key] = true;

  if (!group || !group.controls) {
    return errorObject;
  }

  const values = group.value;
  const newPassword = values.password;

  let oldAndNewPasswordSetTogether = true;
  if (newPassword.length) {
    const oldPassword = values.oldPassword;
    if (!oldPassword.length) {
      oldAndNewPasswordSetTogether = false;
    }
  }

  return oldAndNewPasswordSetTogether ? null : errorObject;
};
