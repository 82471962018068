import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { AlertService } from './alert.service';
import { Subscription } from 'rxjs';
import { Alert } from '../models/alert.interface';
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";
import {Router} from "@angular/router";

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  alert: Alert;
  alertsSub: Subscription;
  messageAlerte: SafeHtml;
  constructor(
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // Display only the last message
    this.alertsSub = this.alertService.getAlertListener().subscribe(
      alerts => {
        this.alert = alerts && alerts.length > 0 ? alerts.pop() : null;
        if(this.alert !== null) {
          this.messageAlerte = this.sanitizer.bypassSecurityTrustHtml(this.alert.message);

          this.changeDetectorRef.detectChanges();
          let link = document.getElementById('link');
          if(link) {
            let routerLink = link.getAttribute('routerLink');

            if (routerLink !== null) {
              link.addEventListener('click', () => {
                this.router.navigate([routerLink]);
              })
            }
          }
        }
      }
    );
  }
}
