import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DisplayBsClassService {
  badgeClassConversion = {
    missing: 'primary',
    waiting: 'warning',
    valid: 'success',
    rejected: 'danger'
  };

  paymentClassConversion = {
    canceled: 'rouge',
    waiting: 'orange',
    valid: 'vert',
    rejected: 'rouge',
    gift: 'bleu'
  };

  zoneClassConversion = {
    1: 'bleu',
    2: 'jaune',
    3: 'rouge',
    4: 'vert'
  };

  zoneBadgeClassConversion = {
    1: 'primary',
    2: 'warning',
    3: 'danger',
    4: 'success'
  };

  jacketSizeClassConversion = {
    XS: 'primary',
    S: 'secondary',
    M: 'success',
    L: 'warning',
    XL: 'danger',
    XXL: 'info',
    XXXL: 'light'
  };

  statusClassConversion = {
    waiting: 'orange',
    valid: 'vert',
    invalid: 'rouge',
    saved: 'bleu'
  };

  badgeStatusClassConversion = {
    waiting: 'warning',
    valid: 'success',
    invalid: 'danger',
    saved: 'primary'
  };

  fallbackClass = 'dark';

  constructor() { }

  getFileBadgeClass(fileStatus: string) {
    if (this.badgeClassConversion.hasOwnProperty(fileStatus)) {
      return this.badgeClassConversion[fileStatus];
    }
    return this.fallbackClass;
  }

  getPaymentClass(status: string) {
    if (this.paymentClassConversion.hasOwnProperty(status)) {
      return this.paymentClassConversion[status];
    }

    return this.fallbackClass;
  }

  getZoneClass(zone: number) {
    if (this.zoneClassConversion.hasOwnProperty(zone)) {
      return this.zoneClassConversion[zone];
    }
    return '';
  }

  getZoneBadgeClass(zone: number) {
    if (this.zoneBadgeClassConversion.hasOwnProperty(zone)) {
      return this.zoneBadgeClassConversion[zone];
    }

    return this.fallbackClass;
  }

  getJacketClass(size: string) {
    if (this.jacketSizeClassConversion.hasOwnProperty(size)) {
      return this.jacketSizeClassConversion[size];
    }
    return this.fallbackClass;
  }

  getTeamMembersBadgeClass(membersNumber: number) {
    return membersNumber > 3 ? 'info' : 'success';
  }

  getEntryStatusClass(status: string) {
    if (this.statusClassConversion.hasOwnProperty(status)) {
      return this.statusClassConversion[status];
    }
    return this.fallbackClass;
  }

  getEntryBadgeClass(status: string) {
    if (this.badgeStatusClassConversion.hasOwnProperty(status)) {
      return this.badgeStatusClassConversion[status];
    }

    return this.fallbackClass;
  }

  getValidDocumentsClass(areValid: boolean) {
    return areValid ? 'success' : 'warning';
  }
}
