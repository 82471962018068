import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subscription} from 'rxjs';
import {AuthService} from '../../auth/auth.service';
import {LangService} from '../../core/lang/lang.service';
import {atLeastOne} from '../../shared/validators/at-least-one.validator';
import {dateInterval} from '../../shared/validators/date-interval.validator';
import {equals} from '../../shared/validators/equals.validator';
import {PilotRegistrationService} from 'src/app/pilot/pilot-registration/pilot-registration.service';
import {ConfigurationService} from 'src/app/core/configuration.service';

@Component({
  selector: 'app-create-pilot-registration',
  templateUrl: './create-pilot-registration.component.html',
  styleUrls: []
})
export class CreatePilotRegistrationComponent implements OnInit, OnDestroy {
  roleSub: Subscription;
  pilotSub: Subscription;
  pilotForm: FormGroup;
  modalRef: BsModalRef;

  formSteps = [
    'credentials', 'informations'
  ];
  currentFormStep = 'credentials';

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private pilotRegistrationService: PilotRegistrationService,
    private configurationService: ConfigurationService,
    private modalService: BsModalService,
    private router: Router,
    public route: ActivatedRoute,
    public langService: LangService,
    public authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.initForm();

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (this.authService.getRole() === 'pilot') {
        this.router.navigate(['/pilot']);
      }

      this.pilotSub = this.pilotRegistrationService.getCurrentPilotListener().subscribe((pilot) => {
        if (pilot != null) {
          this.router.navigate(['/pilot']);
        }
      });

      this.roleSub = this.authService.getRoleListener().subscribe(role => {
        if (paramMap.has('pilotId') && role === 'anonymous') {
          this.router.navigate(['/pilot']);
        }
      });
    });
  }

  ngOnDestroy() {
    if(this.roleSub) {
      this.roleSub.unsubscribe();
    }

    if(this.pilotSub) {
      this.pilotSub.unsubscribe();
    }
  }

  initForm() {
    const optionalInternationalPhoneRegex = /^(\+?(\d[ \.\-]?){5,14}\d)?$/;

    this.pilotForm = this.formBuilder.group({
      personalData: this.formBuilder.group({
        name: new FormControl('', Validators.required),
        forname: new FormControl('', Validators.required),
        sex: new FormControl('', Validators.required),
        nationality: new FormControl('', Validators.required),
        birthDate: new FormControl('', Validators.required),
        birthCity: new FormControl('', Validators.required),
        birthCountry: new FormControl('', Validators.required),
        firstParticipation: new FormControl('', Validators.required)
      }),
      licence: this.formBuilder.group({
        number: new FormControl('', Validators.required),
        delivery: new FormControl('', Validators.required),
        flightHours: new FormControl('', Validators.required),
        licenceCountry: new FormControl('', Validators.required)
      }),
      coordinates: this.formBuilder.group({
        address: new FormControl('', Validators.required),
        zipCode: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
        country: new FormControl('', Validators.required)
      }),
      phones: this.formBuilder.group({
        phone: new FormControl('', Validators.pattern(optionalInternationalPhoneRegex)),
        cellphone: new FormControl('', Validators.pattern(optionalInternationalPhoneRegex))
      }),
      connectionData: this.formBuilder.group({
        email: new FormControl('', [Validators.required, Validators.email]),
        emailConfirmation: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        passwordConfirmation: new FormControl('', [Validators.required, Validators.minLength(6)])
      }),
      arrivalDeparture: this.formBuilder.group({
        arrival: new FormControl('', [Validators.required, dateInterval]),
        departure: new FormControl('', [Validators.required, dateInterval])
      }),
      pilotFiles: this.formBuilder.group({
        licence: new FormControl(''),
        licenceInput: new FormControl(''),
        medicalCertificate: new FormControl(''),
        medicalCertificateInput: new FormControl(''),
        flightLog: new FormControl(''),
        flightLogInput: new FormControl('')
      }),
      commitment: this.formBuilder.group({
        isAccepted: new FormControl('', [Validators.requiredTrue]),
        receiveNewsletter: new FormControl('')
      }),
      recaptcha: new FormControl('', [Validators.required]),
      id: new FormControl('')
    });

    this.pilotForm.controls.phones.setValidators(atLeastOne(Validators.required, ['phone', 'cellphone']));
    this.pilotForm.controls.connectionData.setValidators([
      equals(['email', 'emailConfirmation'], 'emailsEquals'),
      equals(['password', 'passwordConfirmation'], 'passwordsEquals'),
    ]);

    this.pilotForm.get('recaptcha').setValidators([]);
  }

  getConnectionForm(): FormGroup {
    return this.pilotForm.controls.connectionData as FormGroup;
  }
}

