import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Alert } from '../models/alert.interface';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
// will contain the factorised methods for other services
export class AlertService {
  alerts: Alert[] = [];
  alertListener = new Subject<Alert[]>();
  public defaultDuration = 10;

  constructor(private router: Router) {
    router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
          this.clear();
      }
    });
  }

  getAlertListener() {
    return this.alertListener.asObservable();
  }

  /**
   * Adds an alert message.
   * @param type
   * @param message
   * @param duration the duration of the alert (0 --> until page change)
   */
  addAlert(type: string, message: string, duration: number = this.defaultDuration) {
    this.alerts.push(<Alert>{type: type, message: message});
    window.scrollTo(0, 0);
    this.emit();

    if (duration === 0) {
      return;
    }

    setTimeout(
      () => {
        this.alerts.splice(
          this.alerts.indexOf(
            this.alerts.find(x => x.message === message && x.type === type)
          ),
          1
        );
        this.emit();
      },
      duration * 1000
    );
  }

  emit() {
    return this.alertListener.next([...this.alerts]);
  }

  getAlerts() {
    return [...this.alerts];
  }

  clear() {
    this.alerts = [];
    this.alertListener.next();
  }
}
