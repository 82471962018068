import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from 'src/app/core/date.service';
@Pipe({
  name: 'str-to-date'
})
export class StrToDatePipe implements PipeTransform {

  constructor(
    private dateService: DateService
  ) { }

  transform(apiDate: string): Date {
    // NB: we add +1 because of some strange behavior from javascript
    const offset = (this.dateService.today.getTimezoneOffset() / 60) + 1;
    const offsetStr = (offset < 0 ? '+' : '-') + (Math.abs(offset) + '00').padStart(4, '0');
    return new Date(apiDate + 'T00:00:00' + offsetStr);
  }
}
